import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import arrowleft from "../Assets/img/Icons/icon-arrow-1.png";
import arrowright from "../Assets/img/Icons/icon-arrow-2.png";
function PrivacyPolicy() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);
  return (
    <>
      {/* Privacy Policy Starts Here */}
      <section className="terms-policy pp-bg container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" className="text-center">
                <h1>
                  <img src={arrowleft} alt="Arrow Left" /> Privacy Policy{" "}
                  <img src={arrowright} alt="Arrow Right" />
                </h1>
              </Col>
              <Col xl="12" lg="12" md="12">
                <p>
                  This policy (“Policy”, together with our Terms of Use )
                  explains and sets out the basis for why and when we collect
                  personal information about the people who visit our
                  website(s), how we use it, the conditions under which we may
                  disclose it to others and the measures we take to keep it
                  secure.
                </p>
                <p>
                  By visiting this website you are accepting and consenting to
                  the practices described in this Policy. Please note that this
                  includes consenting to the collection and processing of any
                  personal information you provide, as described below. We may
                  amend this Policy from time to time so please check it
                  occasionally to ensure that you agree with any changes. Your
                  continued use of our website(s) will constitute your
                  acceptance of, and agreement to, any changes.
                </p>
                <h3>How we collect information about you</h3>
                <p>
                  We obtain information about you when you use our website, when
                  you contact us via email or a web form or if you register to
                  receive one of our regular newsletters. We may collect and
                  process the following types of information about you.
                </p>
                <h3>Information you provide us</h3>
                <p>
                  You may provide us with personal information by filling in
                  forms on our website(s) or by corresponding with us by e-mail
                  or live chat.
                </p>
                <p>
                  The personal information you provide may include your name,
                  email, address, phone number, IP address, language preference,
                  and information regarding the pages you access. By providing
                  us with this information, you expressly consent to our use of
                  your personal information in accordance with this Policy.
                </p>
                <h3>Information we collect about you</h3>
                <p>
                  When you visit our website(s) we may collect information about
                  your IP address, information about your visit, your browsing
                  activity, and how you use our website. This information may be
                  combined with other information you provide.
                </p>
                <h3>Information we receive from other sources</h3>
                <p>
                  We may receive information about you if you use any other
                  websites we, or our partners, operate. We also work with third
                  parties (including, contractors, project partners, service
                  providers, analytics providers) and may receive information
                  about you from them. This may be combined with other
                  information you provide to us.
                </p>
                <h3>How your information is used</h3>
                <p>
                  We may use personal information about you for the following
                  purposes to provide you with relevant information and news.
                </p>
                <p>
                  To send you personalized communications which you have
                  requested and that may be of interest to you, which may be
                  based on your activity on our website(s) or the website of our
                  partners. These may include information about campaigns,
                  activities, and events. To understand and measure the
                  effectiveness of how we serve you and others. to make
                  suggestions and recommendations to you about services that may
                  interest you, which may be based on your activity on our
                  website(s) or the website of our partners. For analytics and
                  profiling to create aggregate trend reports, find out how
                  visitors arrive at our website; which sites and pages are
                  viewed, the responses to marketing campaigns, and to determine
                  the most effective marketing channels, seek your views or
                  comments on the service we provide, notify you of changes to
                  our service, policies, and terms of use.
                </p>
                <p>
                  We review our retention periods for personal information on a
                  regular basis. We will hold your personal information on our
                  systems for as long as is necessary for the relevant activity.
                </p>
                <h3>Who has access to your information</h3>
                <p>
                  We will not sell or rent your information to third parties. We
                  will not share your information with third parties for
                  marketing purposes. We may pass your information to
                  third-party service providers, agents, subcontractors, and
                  other associated organizations for the purposes of completing
                  tasks and providing services to you on our behalf (for example
                  to process and store information and to send you emails).
                </p>
                <p>
                  However, if we use third-party service providers, we disclose
                  only the personal information that is necessary to deliver the
                  service and we have a contract in place that requires them to
                  keep your information secure and not to use it for their own
                  direct marketing purposes.
                </p>
                <p>
                  We will take the necessary steps to ensure that your privacy
                  rights continue to be protected.
                </p>
                <h3>Your choices and your rights</h3>
                <p>
                  You have the right to inform us not to process your personal
                  information for marketing purposes. You can exercise your
                  right and prevent us from processing such information by
                  checking or unchecking certain boxes on the forms we use to
                  collect your data. You can also exercise the right at any time
                  by contacting us by email at support@[ New Dawn Finance ].ai{" "}
                  <a target="_balnk" href="support@[ New Dawn Finance ].ai">
                    support@[ New Dawn Finance ].ai
                  </a>
                </p>
                <p>
                  We will not contact you for marketing purposes unless you have
                  given your prior consent. You can change your marketing
                  preferences at any time by contacting us by email at
                  <a target="_balnk" href="support@[ New Dawn Finance ].ai.">
                    support@[ New Dawn Finance ].ai.
                  </a>
                  .
                </p>
                <h3>How you can update your information</h3>
                <p>
                  The accuracy of your information is important to us. If you
                  change your email address, or any of the other information we
                  hold is inaccurate or out of date, please
                </p>
                <p>
                  email us at support@[ New Dawn Finance ].ai You have the right
                  to ask for a copy of the information [ New Dawn Finance ]
                  holds about you.
                </p>
                <h3>Security measures to protect your information</h3>
                <p>
                  When you give us personal information, we take steps to ensure
                  that it’s treated securely.
                </p>
                <p>
                  Non-sensitive details such as your email address may be
                  transmitted unencrypted over the Internet, and so may not be
                  guaranteed to be 100% secure. While we strive to protect your
                  personal information, we cannot guarantee the security of any
                  information you transmit to us, and you do so at your own
                  risk. When we receive your information, we make our best
                  effort to ensure its security on our systems.
                </p>
                <h3>Profiling</h3>
                <p>
                  We may analyse your personal information to create a profile
                  of your preferences and interests so that we can contact you
                  with information relevant to you. We may make use of
                  additional information about you when it is available from
                  external sources to help us do this effectively.
                </p>
                <h3>Use of Cookies</h3>
                <p>
                  Our site(s) use cookies to distinguish you from other visitors
                  of our site. Cookies are small text files that may be stored
                  on your computer (or other internet-enabled devices, such as a
                  smartphone or tablet. They are sent by a server to your
                  computer and stored on your hard drive to allow a website to
                  recognise you when you visit.
                </p>
                <p>
                  Cookies can be used by websites to make the user’s experience
                  more efficient. Our site(s) use different types of cookies.
                  Some cookies are placed by third-party services that appear on
                  our pages. We use cookies to personalize content, provide
                  social media features and analyse our traffic. This improves
                  your experience when you browse our site(s) and allows us to
                  improve the service we provide. By continuing to browse our
                  site(s), you are agreeing to our use of cookies.
                </p>
                <p>
                  We also share information about your use of our site with our
                  social media and analytics partners who may combine it with
                  other information that you have provided to them or that they
                  have collected from your use of their services.
                </p>
                <p>
                  You can at any time change or withdraw your consent from the
                  cookie declaration on our website. Your consent applies to the
                  following domains: [ New Dawn Finance ].ai, buy.[ New Dawn
                  Finance ].ai.
                </p>
                <h2>We use the following cookies:</h2>
                <h3>Strictly Necessary Cookies</h3>
                <p>
                  These cookies are necessary for the website to function and
                  cannot be switched off in our systems. They are usually only
                  set in response to actions made by you which amount to a
                  request for services, such as setting your privacy
                  preferences, logging in or filling in forms. These cookies
                  enable basic functions like page navigation and access to
                  secure areas of the website. The website cannot function
                  properly without these cookies.
                </p>
                <h3>Targeting Cookies</h3>
                <p>
                  These cookies may be set through our site by our advertising
                  partners. Marketing cookies are used to track visitors across
                  websites. They may be used by those companies to build a
                  profile of your interests and show you relevant adverts on
                  other sites. The intention is to display ads that are relevant
                  and engaging for the individual user and thereby more valuable
                  for publishers and third-party advertisers. They do not store
                  directly personal information, but are based on uniquely
                  identifying your browser and internet device. If you do not
                  allow these cookies, you will experience less targeted
                  advertising.
                </p>
                <h3>Performance Cookies</h3>
                <p>
                  These cookies are usually referred to as statistical cookies
                  and allow us to count visits, traffic sources so we can
                  measure and improve the performance of our site. They help us
                  to know which pages are the most and least popular and see how
                  visitors move around the site. All information these cookies
                  collect is aggregated and therefore anonymous. If you do not
                  allow these cookies we will not know when you have visited our
                  site, and will not be able to monitor its performance.
                </p>
                <h3>Links to other websites</h3>
                <p>
                  Our website may contain links to other websites run by other
                  organisations. This Policy applies only to our website‚ so we
                  encourage you to read the privacy statements on the other
                  websites you visit. We cannot be responsible for the privacy
                  policies and practices of other sites even if you access them
                  using links from our website.
                </p>
                <p>
                  If you linked to our website from a third-party site, we
                  cannot be responsible for the privacy policies and practices
                  of the owners and operators of that third-party site and
                  recommend that you check the policy of that site.
                </p>
                <h3>16 or Under</h3>
                <p>
                  We are concerned to protect the privacy of children aged 16 or
                  under. If you are aged 16 or under‚ please get your
                  parent/guardian’s permission beforehand whenever you provide
                  us with personal information.
                </p>
                <h3>Where we store your personal information</h3>
                <p>
                  The data that we collect from you may be transferred to, and
                  stored in, a country outside the European Economic Area (EEA).
                  It may also be processed by staff operating outside the EEA
                  who work for us. The laws in some countries may not provide
                  the same legal protection for your information as in the EEA.
                  By submitting your personal information, you agree to this
                  transfer, storing or processing. We will take all steps
                  reasonably necessary to ensure that your data is treated
                  securely and in accordance with this privacy policy.
                </p>
                <p>
                  The transmission of information via the internet is not
                  completely secure. Although we will always do our best to
                  protect your personal information, we cannot guarantee the
                  security of your information; any transmission is at your own
                  risk. Once we have received your information, we will use good
                  procedures and strong security features to try to prevent
                  unauthorised access.
                </p>
                <h3>Data Protection Officer</h3>
                <p>
                  [ New Dawn Finance ].ai has appointed an internal data
                  protection officer for you to contact if you have any
                  questions regarding this Policy and our privacy practices. The
                  data protection officer can be contacted as follows:
                  <a target="_balnk" href="support@[ New Dawn Finance ].ai">
                    support@[ New Dawn Finance ].ai
                  </a>
                </p>
                <div className="spacer-40" />
              </Col>
              {/* <Col xl="12" lg="12" md="12" className="text-center">
                <Link className="reg-btn br-radius trans" to="/">
                  Back To Homepage
                </Link>
              </Col> */}
            </Row>
          </Container>
        </Row>
      </section>
      {/* Privacy Policy Ends Here */}
    </>
  );
}
export default PrivacyPolicy;
