import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import bg from "../Assets/img/Bg/bg-1.png";
import { Row, Container, Col } from "react-bootstrap";
import videoSource from "../Assets/img/video/dawn.mp4";

function Header({ setSignupShow, SignupShow }) {
  return (
    <>
      <Container fluid className="landing-header header text-center">
        <div className="bg-layer">
          <div className="video-wrapper">
            <video className="bg-layer__video" autoPlay loop playsInline muted>
              <source src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            {/* Your other React components/content goes here */}
          </div>
        </div>
        <Row>
          <Container>
            <Row
              onClick={() => {
                setSignupShow(true);
              }}
            >
              <Col xl="12" lg="12">
                <div className="text-pnl">
                  <h5>Accessibility, Transparency, Security</h5>
                  <h1>
                    Ushering a New Dawn With Decentralization - Earn Passive
                    Income
                  </h1>
                  <div className="spacer-20" />
                  <h4>
                    Join the First Decentralized Hedge Fund That Offers <span className="text-gradient">Over
                    8.2%* Monthly Yield </span><br/>Managed Entirely By Artificial
                    Intelligence
                  </h4>
                  <div className="spacer-20" />
                  <ul className="btn-list">
                    <li>
                      <Link className="reg-btn br-radius" href="#">
                        Join Presale
                      </Link>
                    </li>
                    <li>
                      <Link className="reg-btn trans br-radius" href="#">
                        Learn More
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  );
}
export default Header;
