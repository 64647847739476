import React from "react";
import bg1 from "../Assets/img/Roadmap/bg-1.png";
import bg2 from "../Assets/img/Roadmap/bg-7.png";
import bg3 from "../Assets/img/Roadmap/bg-8.png";
import bg4 from "../Assets/img/Roadmap/bg-9.png";
import bg5 from "../Assets/img/Roadmap/bg-10.png";
import bg6 from "../Assets/img/Roadmap/bg-11.png";
import post1 from "../Assets/img/Icons/icon-ui.png";
import post2 from "../Assets/img/Icons/icon-fees.png";
import post3 from "../Assets/img/Icons/icon-gas.png";
import post4 from "../Assets/img/Icons/icon-protection.png";
import post5 from "../Assets/img/Icons/icon-variety.png";
import post6 from "../Assets/img/Icons/icon-trading.png";
import sunbg from "../Assets/img/Bg/bg-4.png";
function Aboutlist() {
  return (
    <>
      <ul className="about-list">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${sunbg})` }}
        />
        <li>
          <div className="img-pnl">
            <span>
              <img src={bg1} alt="post" />
            </span>
            <img src={post1} alt="post" />
          </div>
          <div className="txt-pnl">
            <h4>Effortless Wealth Generation</h4>
            <p>
              <span>New Dawn Finance</span> eliminates the need for active
              trading—simply hold your tokens and benefit from sustained
              portfolio growth powered by our advanced technology.
            </p>
          </div>
        </li>
        <li>
          <div className="img-pnl">
            <span>
              <img src={bg2} alt="post" />
            </span>
            <img src={post2} alt="post" />
          </div>
          <div className="txt-pnl">
            <h4>Powered by Intelligent Trading Engine</h4>
            <p>
              Powered by a combination of sophisticated AI algorithms and
              real-time market analysis, <span>New Dawn Finance</span>{" "}
              replicates expert-level trading strategies, managing complex
              trades autonomously to maximize returns.
            </p>
          </div>
        </li>
        <li>
          <div className="img-pnl">
            <span>
              <img src={bg4} alt="post" />
            </span>
            <img src={post4} alt="post" />
          </div>
          <div className="txt-pnl">
            <h4>Decentralized Hedge Fund Structure</h4>
            <p>
              Our decentralized ownership and management structures ensure that{" "}
              <span>New Dawn Finance</span> operates independently. Our profits
              are transparently distributed to holders directly.
            </p>
          </div>
        </li>
        <li>
          <div className="img-pnl">
            <span>
              <img src={bg3} alt="post" />
            </span>
            <img src={post3} alt="post" />
          </div>
          <div className="txt-pnl">
            <h4>Automated Buy-Back Mechanism</h4>
            <p>
              Through a continuous buy-back and burn cycle,{" "}
              <span>New Dawn Finance</span> reduces token supply, enhancing
              long-term value and strengthening investor returns.
            </p>
          </div>
        </li>
        <li>
          <div className="img-pnl">
            <span>
              <img src={bg5} alt="post" />
            </span>
            <img src={post5} alt="post" />
          </div>
          <div className="txt-pnl">
            <h4>Round-the-Clock Trading</h4>
            <p>
              <span>New Dawn Finance</span> takes advantage of the continuous
              nature of blockchain technology to scout for opportunities 24/7.
              This maximizes profit generation and ensures capitalization on
              market movements.
            </p>
          </div>
        </li>
      </ul>
    </>
  );
}
export default Aboutlist;
