import axios from 'axios';
import { Api_key, Base_Url, NowPaymentBaseUrl } from '../Constants';
import { Notifications } from '../Components/Toast';


export const Signup = async (body) => {
  
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post('accounts/Signup',body, {
        baseURL: Base_Url,
      });
      localStorage.setItem("JwtToken",response.data.token )
   
      resolve(response.data);
    } catch (error) {
      // console.log( error)

        Notifications('error',error.response.data.message)
      reject(error);
    }
  });
};


export const Login = async (body) => {
    // console.log(body)
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post('accounts/Login',body, {
        baseURL: Base_Url,
      });
      if(response?.data?.message==='User is block')
      {
        Notifications('error',"Your account is blocked ")
        reject('error');
      }
      // console.log(response.data.token)
      resolve(response.data);

    } catch (error) {
        Notifications('error',"Email or Password is incorrect")
      reject(error);
    }
  });
};

export const UpdateProfileApi = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.post('accounts/update',body, {
      baseURL: Base_Url,
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
    });

    // console.log(response.data.token)
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};

export const UpdateProfilePassword = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.post('accounts/updatePassword',body, {
      baseURL: Base_Url,
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
    });

    // console.log(response.data.token)
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};

export const ResetProfilePassword = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.post('accounts/forgetPasswordRequest',body, {
      baseURL: Base_Url,
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
    });

    // console.log(response.data.token)
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};
export const forgetPasswordApi = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.post('accounts/forgetPassword',body, {
      baseURL: Base_Url,
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
    });

    // console.log(response.data.token)
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};


export const getUserDetail = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get('accounts/getDetailofUser', {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      if(error?.response?.data?.message)
            Notifications('error',"Your Account Is Blocked")


    reject(error);
  }
});
};
export const getDetailofUserwithWallet = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/getDetailofUserwithWallet?address=${body}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {

      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};


export const getUserActivity = async (id,page) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/getUserActivity?userId=${id}&CurrentPage=${page}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};
export const deleteActivities = async (id,page) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/deleteActivities?userId=${id}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};
export const Enable2FA_Api = async (id,page) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/enable2FA?userId=${id}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};
export const CheckFactorVerification = async (data) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.post(`accounts/CheckFactorVerification`,data, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};






export const getTotalTokenOfUser = async (id) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/getTotalTokenOfUser?userId=${id}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};

// Bonus = false , Refral = false , purchase = false
export const getPurchaseTokenOfUser = async (id) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/getTotalTokenOfUser?userId=${id}&purchase=true`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};
export const getReferralTokenOfUser = async (id) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/getTotalTokenOfUser?userId=${id}&Refral=true`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};

export const getBonusTokenOfUser = async (id) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/getTotalTokenOfUser?userId=${id}&Bonus=true`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};



export const GetCurrentStage = async (body) => {
    // console.log(body)
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get('stages/getActiveStage', {
        baseURL: Base_Url,
      });
      resolve(response.data);
    } catch (error) {
        //  Notifications('error',"Email is Already Taken")
      reject(error);
    }
  });
};

export const GetPromoCode = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get('stages/getPromotion', {
      baseURL: Base_Url,
    });
    resolve(response.data);
  } catch (error) {
      //  Notifications('error',"Email is Already Taken")
    reject(error);
  }
});
};

export const GetCurrentStageTransactions = async (stageId) => {
    // console.log(body)
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`transactions/getCountOfTransactionOfStage?stageId=${stageId}`, {
        baseURL: Base_Url,
      });
      resolve(response.data);
    } catch (error) {
        //  Notifications('error',"Email is Already Taken")
      reject(error);
    }
  });
};

export const GetAllStageTransactions = async (stageId) => {
    // console.log(body)
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`transactions/getCountOfTransactionOfStage`, {
        baseURL: Base_Url,
      });
      resolve(response.data);
    } catch (error) {
        //  Notifications('error',"Email is Already Taken")
      reject(error);
    }
  });
};




export const MakeTransaction = async (body) => {
  try {
    const response = await axios.post(
      `${Base_Url}/transactions/CreateTransaction`, // Assuming Base_Url is defined somewhere
      body, // Assuming 'body' contains the data you want to send in the request
      {
        headers: {
          Authorization: localStorage.getItem('JwtToken'),
        },
      }
    );
    return response.data;
  } catch (error) {
    // Handle errors or throw them for higher-level error handling
    throw error;
  }
};
export const MakeTransactionWithAddress = async (body) => {
  try {
    const response = await axios.post(
      `${Base_Url}/transactions/CreateTransactionWithAddress`, // Assuming Base_Url is defined somewhere
      body, // Assuming 'body' contains the data you want to send in the request
      {
        headers: {
          Authorization: localStorage.getItem('JwtToken'),
        },
      }
    );
    return response.data;
  } catch (error) {
    // Handle errors or throw them for higher-level error handling
    throw error;
  }
};



export const UpdateTransaction = async (body) => {
  try {
    const response = await axios.post(
      `${Base_Url}/transactions/UpdateTransaction`, // Assuming Base_Url is defined somewhere
      body, // Assuming 'body' contains the data you want to send in the request
      {
        headers: {
          Authorization: localStorage.getItem('JwtToken'),
        },
      }
    );
    return response.data;
  } catch (error) {
    // Handle errors or throw them for higher-level error handling
    throw error;
  }
};

export const CreatePayment = async (body) => {
  return new Promise(async (resolve, reject) => {
  try {

    const obj = 
      {
        "price_amount": body.amount,
        "price_currency": "usd",
        "pay_currency": body.selectedCurrency==='bnb'?'bnbbsc':body.selectedCurrency,
        "ipn_callback_url": "https://nowpayments.io",
        "order_id": body.transaction_number,
        "order_description": body?.description?? "ALG Token Purchase",
        "is_fixed_rate": false,
        "is_fee_paid_by_user": false
      }
    const response = await axios.post(`${NowPaymentBaseUrl}v1/payment`,obj, {
      headers :{
        'x-api-key' : Api_key,
        'Content-Type' : 'application/json'
      }
      
    });
    resolve(response.data);
  } catch (error) {
      //  Notifications('error',"Email is Already Taken")
    reject(error);
  }
})
};


export const getAllTransactions = async (userId,searchLikeTx,CurrentPage,PageSize,sorting) => {
  try {
    const response = await axios.get(
      `${Base_Url}/transactions/getAllTransactionOfUser?CurrentPage=${CurrentPage}&PageSize=${PageSize}&userId=${userId}&searchLikeTx=${searchLikeTx??''}&sorting=${sorting}`, // Assuming Base_Url is defined somewhere
        // Assuming 'body' contains the data you want to send in the request
      {
        headers: {
          Authorization: localStorage.getItem('JwtToken'),
        },
      }
    );
    return response.data;
  } catch (error) {
    // Handle errors or throw them for higher-level error handling
    throw error;
  }
};

export const searchTransaction = async (transactionhash,userId) => {
  try {
    const response = await axios.get(
      `${Base_Url}/transactions/getAllTransactionOfUser?userId=${userId}&searchTx=${transactionhash}`, // Assuming Base_Url is defined somewhere
        // Assuming 'body' contains the data you want to send in the request
      {
        headers: {
          Authorization: localStorage.getItem('JwtToken'),
        },
      }
    );
    return response.data;
  } catch (error) {
    // Handle errors or throw them for higher-level error handling
    throw error;
  }
};


export const GetAllAvaliableCurrencies = async (stageId) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`/v1/currencies?fixed_rate=true`, {
      baseURL: NowPaymentBaseUrl,
      headers :{
        'x-api-key' : Api_key
      }
    });
    resolve(response.data);
  } catch (error) {
      //  Notifications('error',"Email is Already Taken")
    reject(error);
  }
});
};

export const GetAllAvaliableCurrenciesWithLogo = async (stageId) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`/v1/full-currencies`, {
      baseURL: NowPaymentBaseUrl,
      headers :{
        'x-api-key' : Api_key
      }
    });
    resolve(response.data.currencies);
  } catch (error) {
      //  Notifications('error',"Email is Already Taken")
    reject(error);
  }
});
};


export const GetSelectedCurrencyPrice = async (currency) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`/v1/estimate?amount=1&currency_from=usd&currency_to=${currency}`, {
      baseURL: NowPaymentBaseUrl,
      headers :{
        'x-api-key' : Api_key
      }
    });
    resolve(response.data.estimated_amount);
  } catch (error) {
      //  Notifications('error',"Email is Already Taken")
    reject(error);
  }
});
};





export const getUserReferralHistory = async (id) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/referralhistory?user_Id=${id}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};


export const CheckPromoValidity = async (promo,accountId) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`transactions/CheckPromoCode?promo=${promo}&accountId=${accountId}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};
export const getSumOfCurrencies = async (promo,accountId) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`transactions/totalRaised`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};




export const getTotalTokenOfwallet = async (address) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/getTotalTokenOfwallet?address=${address}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};

export const WalletaddressChanage = async (user_id) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`accounts/WalletaddressChanage?user_id=${user_id}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};

export const WalletaddressRequest = async (data) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.post(`accounts/WalletaddressRequest`,data, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    resolve(response.data);

  } catch (error) {
    Notifications('error',error.response.data.message)
    reject(error);
  }
});
};


export const SignUpWithEmail = async (data) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.post(`accounts/signUpWithEmail`,data, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
    localStorage.setItem("JwtToken",response.data.token )
   
      resolve(response.data);

  } catch (error) {
    Notifications('error',error.response.data.message)
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};


export const getTokenBonusCount = async (stageId) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`admin/getBonusTokenCount?stageId=${stageId}`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
      resolve(response.data);

  } catch (error) {
    Notifications('error',error.response.data.message)
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};

export const getBonusTokenCountWithOutStage = async () => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`admin/getBonusTokenCountwithOutStage`, {
      headers:{
        Authorization : `${localStorage.getItem('JwtToken')}`
      },
      baseURL: Base_Url,
    });
      resolve(response.data);

  } catch (error) {
    Notifications('error',error.response.data.message)
      // Notifications('error',"Email or Password is incorrect")
    reject(error);
  }
});
};



export const getSaleInStage = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`stages/getSaleInStage?StageID=${body}`, {
      baseURL: Base_Url,
    });
    resolve(response.data);
  } catch (error) {
      //  Notifications('error',"Email is Already Taken")
    reject(error);
  }
});
};

// GraphTotal
export const GraphTotal = async (body) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.get(`transactions/GraphTotal`, {
      baseURL: Base_Url,
    });
    resolve(response.data);
  } catch (error) {
      //  Notifications('error',"Email is Already Taken")
    reject(error);
  }
});
}; 


export const ValidateOTPonLogin = async (data) => {
  // console.log(body)
return new Promise(async (resolve, reject) => {
  try {
    const response = await axios.post(`accounts/handleOTPLogin`,data, {
      baseURL: Base_Url,
    });
    resolve(response.data);
  } catch (error) {
      //  Notifications('error',"Email is Already Taken")
    reject(error);
  }
});
}; 

