import React from "react";
import bg1 from "../Assets/img/Roadmap/bg-13.png";
import bg2 from "../Assets/img/Roadmap/bg-2.png";
import bg3 from "../Assets/img/Roadmap/bg-3.png";
import bg4 from "../Assets/img/Roadmap/bg-4.png";
import bg5 from "../Assets/img/Roadmap/bg-5.png";
import bg6 from "../Assets/img/Roadmap/bg-6.png";
import bg7 from "../Assets/img/Roadmap/bg-12.png";
import bg8 from "../Assets/img/Roadmap/bg-14.png";
import bg9 from "../Assets/img/Roadmap/bg-15.png";
import bg10 from "../Assets/img/Roadmap/bg-16.png";
function Roadmaplist() {
  return (
    <>
      <ul className="roadmap-list">
        <li className="active">
          <div className="heading">
            <span>
              <div
                className="bg-layer"
                style={{ backgroundImage: `url(${bg1})` }}
              />
              <i>1</i>
            </span>
            <h5>NDF Protocol Architecture & UI Design Release</h5>
          </div>
        </li>
        <li className="active">
          <div className="heading">
            <h5>Uniswap Listing</h5>
            <span>
              <div
                className="bg-layer"
                style={{ backgroundImage: `url(${bg2})` }}
              />
              <i>2</i>
            </span>
          </div>
        </li>
        <li className="active">
          <div className="heading">
            <span>
              <div
                className="bg-layer"
                style={{ backgroundImage: `url(${bg3})` }}
              />
              
              <i>3</i>
            </span>
            <h5>NDF Index Development</h5>
          </div>
        </li>
        <li>
          <div className="heading">
            <h5>NDF Index Integration Testing</h5>
            <span>
              {" "}
              <div
                className="bg-layer"
                style={{ backgroundImage: `url(${bg4})` }}
              />{" "}
              
              <i>4</i>
            </span>
          </div>
        </li>
        <li>
          <div className="heading">
            <span>
              <div
                className="bg-layer"
                style={{ backgroundImage: `url(${bg5})` }}
              />{" "}
              
              <i>5</i>
            </span>
            <h5>NDF Index Third-party Audit</h5>
          </div>
        </li>
        <li>
          <div className="heading">
            <h5>NDF Index ETH Mainnet Release</h5>
            <span>
              <div
                className="bg-layer"
                style={{ backgroundImage: `url(${bg6})` }}
              />{" "}
              
              <i>6</i>
            </span>
          </div>
        </li>
        <li>
          <div className="heading">
            <span>
              <div
                className="bg-layer"
                style={{ backgroundImage: `url(${bg7})` }}
              />{" "}
              
              <i>7</i>
            </span>
            <h5>NDF Index BSC and HECO Mainnet Release</h5>
          </div>
        </li>
        <li>
          <div className="heading">
            <h5>Yield Optimized Vault Release</h5>
            <span>
              <div
                className="bg-layer"
                style={{ backgroundImage: `url(${bg10})` }}
              />
              
              <i>8</i>
            </span>
          </div>
        </li>
        <li>
          <div className="heading">
            <span>
              <div
                className="bg-layer"
                style={{ backgroundImage: `url(${bg9})` }}
              />{" "}
              
              <i>9</i>
            </span>
            <h5>Leveraged Index Release</h5>
          </div>
        </li>
        <li>
          <div className="heading">
            <h5>Actively Managed Indexes Feature Release</h5>
            <span>
              <div
                className="bg-layer"
                style={{ backgroundImage: `url(${bg8})` }}
              />{" "}
              
              <i>10</i>
            </span>
          </div>
        </li>
      </ul>
    </>
  );
}
export default Roadmaplist;
