import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Container,
  Col,
  Table,
  Button,
  Form,
  Modal,
  PageItem,
} from "react-bootstrap";
import NavBarDash from "../../Components/NavBarDash";
import icontransaction from "../../Assets/img/admin-icons/icon-swap.png";
import iconwallet from "../../Assets/img/admin-icons/icon-wallet.png";
import iconeye from "../../Assets/img/admin-icons/icon-eye.png";
import carrot from "../../Assets/img/Icons/icon-carrot-arrow.png";
import iconi from "../../Assets/img/admin-icons/icon-i.png";
import iconlogo from "../../Assets/img/admin-icons/icon-logo.png";
import iconusdc from "../../Assets/img/admin-icons/icon-udsc.png";
import qrcode from "../../Assets/img/q-r-code.png";
import arrowleft from "../../Assets/img/Icons/icon-arrow-1.png";
import arrowright from "../../Assets/img/Icons/icon-arrow-2.png";
import iconcopy from "../../Assets/img/admin-icons/icon-copy.png";
import Paginationnav from "../../Components/Paginationnav";
import ChatButton from "../../Components/ChatButton";
import {
  CreatePayment,
  UpdateTransaction,
  getAllTransactions,
  getDetailofUserwithWallet,
  searchTransaction,
} from "../../Api";
import moment from "moment";
import UserContest from "../../ContextAPI/UserContest";

import { QRCode } from "react-qrcode-logo";
import CountdownTimer from "../../Components/ExpireyDate";
import { BounceLoader, PulseLoader } from "react-spinners";
import { CopyToClipboardButton } from "../../Constants/utils";
import {
  CurrenicesArray,
  NumberRegex,
  CurrenicesArrayforMetamask,
  TokenAddresses,
  colorWhite,
  CommasRegex,
} from "../../Constants";
import PurchaseTokenWithWallet from "./PurchaseTokenWithWallet";
import coincard from "../../Assets/img/Icons/icon-card.png";
import coinusdc from "../../Assets/img/Icons/icon-usdc.png";
import coinbusd from "../../Assets/img/Icons/icon-busd.png";
import coinbnb from "../../Assets/img/Icons/icon-bnb.png";
import coincrypto from "../../Assets/img/Icons/icon-crypto.png";
import coineth from "../../Assets/img/Icons/icon-eth.png";
import coinusdt from "../../Assets/img/Icons/icon-usdt.png";
import { CircleLoader } from "react-spinners";
import { useAccount, useDisconnect } from "wagmi";
function WalletTransaction() {
  const { UserDetail, CurrentStage } = useContext(UserContest);
  const [showTransaction, setshowTransaction] = useState(false);
  const { currency } = useParams();

  // const address =  " "
  const { address, isConnecting, isDisconnected } = useAccount();
  const [loader, setLoader] = useState(true);

  const [userDeatilofaddress, setuserDeatilofaddress] = useState();
  const navigate = useNavigate();

  const [ConnectModal, setConnectModal] = useState(true);
  const [transactionHistory, settransactionHistory] = useState(false);
  const [SelectedChain, setSelectedChain] = useState();
  const [AmountToSend, setAmountToSend] = useState();
  const Network = {
    mainNetwork: "mainNetwork",
    BUSD: "BUSD",
    USDT: "USDT",
    USDC: "USDC",
  };
  useEffect(() => {
    if (currency === "transaction") {
      setshowTransaction(true);
    } else {
      setshowTransaction(false);
    }
  }, [currency]);

  const [RejecteddetailsShow, setRejecteddetailsShow] = useState(false);
  const [makepaymentShow, setmakepaymentShow] = useState(false);
  const [sellShow, setsellShow] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [selectedtransaction, setSelectedtransaction] = useState({});

  const [searchText, setSearchText] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [toAddress, setToAddress] = useState();
  const [paymentLoader, setpaymentLoader] = useState(false);
  const [ExpireyPayment, setExpireyPayment] = useState();
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setendIndex] = useState();
  useEffect(() => {
    if (!address) navigate("/");

    getDetailofUserwithWallet(address)
      .then((data) => {
        setuserDeatilofaddress(data);
      })
      .catch(() => {});
    // console.log(address)
  }, [address, showTransaction]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchText, itemsPerPage]);

  const GetAllTransactionOnPourchase = () => {
    setLoader(true);
    getAllTransactions(
      userDeatilofaddress?.id,
      searchText,
      currentPage,
      itemsPerPage ?? 1,
      address
    )
      .then((data) => {
        // console.log(data.data);
        setLoader(false);
        setTransactions(data.data);
        setTotalItems(data.totalItems);
      })
      .catch(() => {
        setLoader(false);
        setTotalItems([]);
      });
  };

  useEffect(() => {
    getAllTransactions(
      userDeatilofaddress?.id,
      searchText,
      currentPage,
      itemsPerPage ?? 1,
      address
    )
      .then((data) => {
        // console.log(data.data);
        setTransactions(data.data);
        setLoader(false);
        setTotalItems(data.totalItems);
      })
      .catch(() => {
        setLoader(false);
        setTotalItems([]);
      });
  }, [searchText, userDeatilofaddress, itemsPerPage, address, showTransaction]);

  const searchtransactionByHash = (tnHash) => {
    searchTransaction(tnHash, userDeatilofaddress?.id)
      .then((item) => {
        // console.log(item.data[0])
        setSelectedtransaction(item.data[0]);
      })
      .catch(() => {});
  };
  const ReCreatePayment = (item) => {
    setpaymentLoader(true);
    CreatePayment({
      amount: item.amount_in_dollars,
      selectedCurrency: item.selected_crypto,
      transaction_number: item.transaction_number,
      description: btoa(
        JSON.stringify({
          stage_id: CurrentStage?.id,
          address: address,
          created_from_referral: false,
          referral_used: false,
        })
      ),
    })
      .then((createdPayment) => {
        setToAddress(createdPayment.pay_address);
        setExpireyPayment(createdPayment.expiration_estimate_date);
        setAmountToSend(createdPayment.pay_amount);

        let updateTransaction = {
          AmountToSend: createdPayment.pay_amount,
          expiry_date: createdPayment.expiration_estimate_date,
          payment_id: createdPayment.payment_id,
          toAddress: createdPayment.pay_address,
          transaction_number: item.transaction_number,
          payment_created_date: createdPayment.created_at,
        };
        UpdateTransaction(updateTransaction)
          .then(() => {
            setpaymentLoader(false);
          })
          .catch(() => {});
      })
      .catch(() => {});
  };
  const OnPageChange = (page) => {
    setCurrentPage(page);
    setLoader(true);
    getAllTransactions(
      userDeatilofaddress?.id,
      searchText,
      page,
      itemsPerPage,
      address
    )
      .then((data) => {
        // console.log(data.data);
        setLoader(false);
        setTransactions(data.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    let start = (currentPage - 1) * itemsPerPage;
    setStartIndex((currentPage - 1) * itemsPerPage);
    setendIndex(
      parseInt(start) + parseInt(itemsPerPage) > parseInt(totalItems)
        ? parseInt(start) + parseInt(itemsPerPage) - 1
        : parseInt(start) + parseInt(itemsPerPage)
    );
    //  console.log( parseInt(start) + parseInt(itemsPerPage)   )
  }, [currentPage, searchText, itemsPerPage]);

  function generateButtons(CurrenicesArray) {
    const buttons = [];

    if (CurrenicesArray) {
      const arrayLength = CurrenicesArray.length;

      for (let i = 0; i < arrayLength; i += 2) {
        const item = CurrenicesArray[i];
        const nextIndex = (i + 1) % arrayLength;
        const nextItem = CurrenicesArray[nextIndex];

        buttons.push(
          <div key={i} className="d-flex justify-content-between w-100 mt-5">
            <Button className="reg-btn br-radius trans w-48">
              {item?.toUpperCase()}
            </Button>
            {nextItem && (
              <Button className="reg-btn br-radius trans w-48">
                {nextItem?.toUpperCase()}
              </Button>
            )}
          </div>
        );
      }
    }

    return buttons;
  }

  return (
    <>
      {showTransaction ? (
        <section className="admin-panel container-fluid">
          <Row>
            <Container>
              <Row>
                <Col xl="12" lg="12" md="12">
                  <div className="text-center">
                    <h3 className="font-weight-bolder">
                      <img src={arrowleft} alt="Arrow" /> This table shows all
                      types and status of transactions of address{" "}
                      <img src={arrowright} alt="Arrow" />
                    </h3>
                    <div className="spacer-30" />
                  </div>
                  <div className="blue-post">
                    <div className="flex-div-sm">
                      <div className="d-flex align-items-center mmb-20">
                        <p className="m-0 font-weight-bold">Show</p>
                        <Form.Control
                          // console
                          className=" bg-white px-4 py-2 ml-2 mr-2 border-round dark-color"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            // Allow only numbers from 0 to 9 and an empty string (for backspace)
                            if (NumberRegex.test(inputValue)) {
                              setItemsPerPage(inputValue);
                            }
                          }}
                          onKeyPress={(e) => {
                            // Allow only numbers from 0 to 9 and backspace
                            const keyCode = e.keyCode || e.which;
                            const keyValue = String.fromCharCode(keyCode);
                            if (!NumberRegex.test(keyValue) && keyCode !== 8) {
                              e.preventDefault();
                            }
                          }}
                          value={itemsPerPage}
                          maxLength={2}
                          type="text"
                          autoComplete="new-saarch"
                        />

                        <p className="m-0 font-weight-bold">entries</p>
                      </div>

                      <div className="d-flex align-items-center">
                        <p className="m-0 font-weight-bold">Search:</p>
                        <div className="search-pnl">
                          <Button>
                            <i className="fa fa-search" />
                          </Button>
                          
                           <Form.Control
                          className=" bg-white px-4 py-2 ml-2 mr-2 border-round dark-color w-100"
                          onChange={(e) => { setSearchText(e.target.value) }}
                          value={searchText}
                          type="text"
                          autoComplete="new-saarch"
                          placeholder="Enter Search Request"
                        />
                        </div>
                      </div>
                    </div>
                    <div className="spacer-20" />
                    <div className="table-container">
                      <div className="table-container-inner">
                        <Table striped className="transaction-table">
                          <thead>
                            <tr>
                              <th>
                                <img src={carrot} alt="Arrow" /> Transaction ID
                              </th>
                              <th>
                                <img src={carrot} alt="Arrow" /> Tokens
                              </th>
                              <th>
                                <img src={carrot} alt="Arrow" /> Amount in
                                Cryptocurrency
                              </th>
                              <th>
                                <img src={carrot} alt="Arrow" /> Amount in US
                                Dollars
                              </th>
                              <th>
                                <img src={carrot} alt="Arrow" /> To Wallet
                                Address
                              </th>
                              <th>
                                <img src={carrot} alt="Arrow" /> Type
                              </th>
                              <th>
                                <img src={carrot} alt="Arrow" /> Status
                              </th>
                              <th>
                                <img src={carrot} alt="Arrow" /> Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {loader ? (
                              <>
                                <tr>
                                  <td colSpan={8}>
                                    <div className=" loader-circle">
                                      <CircleLoader
                                        color={colorWhite}
                                        size={42}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <>
                                {transactions?.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>
                                        <p className="m-0 f-18">
                                          {item?.transaction_number}
                                        </p>
                                        <span className="light-yellow">
                                          {moment(item?.created_at).format(
                                            "DD MMM, YYYY hh:mm A"
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        <p className="m-0 f-18">
                                          +{item?.token_purchased}
                                        </p>
                                        <span className="light-yellow">
                                          $NDFT
                                        </span>
                                      </td>
                                      <td>
                                        <p className="m-0 f-18">
                                          {item?.amountafterfee}
                                        </p>
                                        <span className="light-yellow ">
                                          {item?.selected_crypto?.toUpperCase()}{" "}
                                        </span>
                                      </td>
                                      <td>
                                        <p className="m-0 f-18">
                                          {item?.amount_in_dollars}
                                        </p>
                                        <span className="light-yellow ">
                                          USD{" "}
                                          {item?.type === "Purchase" && (
                                            <span className="tooltip-span">
                                              <img src={iconi} alt="Icon i" />
                                              <span>
                                                1 NDFT = $
                                                {item?.tokenpriceatpurchase}{" "}
                                              </span>
                                            </span>
                                          )}
                                        </span>
                                      </td>
                                      <td className="color">
                                        <p className="m-0 f-18">
                                          {!item?.to_wallet_address ? (
                                            "Create Payment"
                                          ) : (
                                            <>
                                              {item?.type === "Bonus" &&
                                                "For Transaction Number "}

                                              {`${item?.to_wallet_address?.slice(
                                                0,
                                                4
                                              )}...${item?.to_wallet_address?.slice(
                                                -4
                                              )}`}
                                            </>
                                          )}
                                        </p>
                                        <span className="light-yellow ">
                                          {item?.payment_created_date &&
                                            moment(item?.payment_created_date)
                                              .utc()
                                              .format("DD MMM, YYYY hh:mm A")}
                                        </span>
                                      </td>
                                      <td className="greencolor">
                                        {item?.type}
                                      </td>
                                      <td className="greencolor">
                                        {item?.status}
                                      </td>
                                      <td>
                                        <Button
                                          disabled={item?.type !== "Purchase"}
                                          className="simple-link purple"
                                          onClick={() => {
                                            if (item?.type === "Bonus")
                                              searchtransactionByHash(
                                                item?.to_wallet_address
                                              );
                                            else setSelectedtransaction(item);
                                            setRejecteddetailsShow(true);
                                          }}
                                        >
                                          <img
                                            className="mr-1"
                                            src={iconwallet}
                                            alt="Arrows"
                                          />{" "}
                                          Pay
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })}
                                {transactions?.length === 0 && (
                                  <tr>
                                    <td colSpan={8}>
                                      <div className="center-text-allign">
                                        No Transaction Found
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <div className="spacer-10" />
                    <div className="flex-div-sm align-items-center">
                      <p className="m-0">
                        <b className="notranslate">
                          Showing {startIndex === 0 ? 1 : startIndex} to{" "}
                          {endIndex} of {totalItems} entries
                        </b>
                      </p>
                      <Paginationnav
                        currentPage={currentPage}
                        totalItems={totalItems}
                        itemsPerPage={
                          parseInt(itemsPerPage) === 0 ||
                          !parseInt(itemsPerPage)
                            ? 1
                            : parseInt(itemsPerPage)
                        }
                        onPageChange={OnPageChange}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
        </section>
      ) : (
        <PurchaseTokenWithWallet
          ChangeNetwork={setConnectModal}
          SelectedNetwork={currency}
          GetAllTransactionOnPourchase={GetAllTransactionOnPourchase}
        />
      )}

      {/* Wallet Address Modal Starts Here */}
      <Modal
        size="lg"
        show={makepaymentShow}
        centered
        onHide={() => setmakepaymentShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button
            className="close-btn"
            onClick={() => setmakepaymentShow(false)}
          >
            <i className="fa fa-close" />
          </Button>
          <h3>Transaction Details</h3>
          <div className="spacer-10" />
          <span className="reject-span letter-spacing-1">rejected</span>
          <div className="spacer-20" />
          <p className="m-0">
            The order no. <span className="color">INQTXN040149</span> was placed
            on 15 Dec, 2024 03:22 PM.
          </p>
          <p className="redcolor">
            Sorry! Your order has been <b className="notranslate">canceled</b>{" "}
            due to payment.
          </p>
          <div className="spacer-30" />
          <p className="yellow-color">
            <b className="notranslate">Token Details</b>
          </p>
          <div className="table-container">
            <div className="table-container-inner">
              <Table striped className="td-brder">
                <tbody>
                  <tr>
                    <td>
                      <p className="m-0">Types</p>
                    </td>
                    <td>
                      <p className="m-0">Purchase</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="m-0">Token of Stage</p>
                    </td>
                    <td>
                      <p className="m-0">Stage 6</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="m-0">Token Amount (T)</p>
                    </td>
                    <td>
                      <p className="m-0">12987 $NDFT</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="m-0">Bonus Token (B)</p>
                    </td>
                    <td>
                      <div className="flex-div">
                        <p className="m-0">1948 $NDFT</p>
                        <p>(0 + 0)</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="m-0">Total Token</p>
                    </td>
                    <td>
                      <div className="flex-div">
                        <p className="m-0">14935 $NDFT</p>
                        <p>(T+B)</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="m-0">Total Payment</p>
                    </td>
                    <td>
                      <div className="flex-div">
                        <p className="m-0">0 USDT</p>
                        <p>250 USD</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <p className="redcolor m-0">
            The transaction was canceled by Administrator at 16 Dec, 2024 04:15
            AM.
          </p>
        </Modal.Body>
      </Modal>
      {/* Wallet Address Modal Ends Here */}

      {/* Make Payment Modal Starts Here */}
      <Modal
        size="lg"
        show={RejecteddetailsShow}
        centered
        onHide={() => setRejecteddetailsShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button
            className="close-btn"
            onClick={() => setRejecteddetailsShow(false)}
          >
            <i className="fa fa-close" />
          </Button>
          <Row>
            <Col xl="12" lg="12" md="12">
              <h3 className="chakra text-center">
                <img src={arrowleft} alt="Arrow Left" /> Make your payment{" "}
                <img src={arrowright} alt="Arrow Right" />
              </h3>
              <div className="spacer-10" />
            </Col>
            <Col xl="12" lg="12" md="12">
              <h5 className="whitecolor ">
                Your Order{" "}
                <span className="color">
                  #{selectedtransaction?.transaction_number}
                </span>{" "}
                waiting for payment.
              </h5>
              <div className="spacer-10" />

              {(selectedtransaction?.amountafterfee || AmountToSend) && (
                <>
                  <p className="m-0">
                    <b className="notranslate">Amount After Fee</b>
                  </p>

                  <h4 className="font-weight-bold ">
                    {selectedtransaction?.amountafterfee ?? AmountToSend}{" "}
                    <span className="font-weight-normal">
                      {selectedtransaction?.selected_crypto?.toUpperCase()}
                    </span>
                  </h4>
                </>
              )}
              <div className="spacer-20" />

              {(selectedtransaction?.to_wallet_address || toAddress) && (
                <>
                  <p className="m-0">
                    <b className="notranslate">To this address</b>
                  </p>
                  <h5 className="whitecolor font-weight-normal  addressTo">
                    {selectedtransaction?.to_wallet_address ?? toAddress}
                  </h5>
                  <Link
                    className="simple-link purple"
                    onClick={() =>
                      CopyToClipboardButton(
                        selectedtransaction?.to_wallet_address,
                        "Address Copied Successfully"
                      )
                    }
                  >
                    <img src={iconcopy} alt="Copy" /> Copy Address
                  </Link>
                </>
              )}
              {!selectedtransaction?.to_wallet_address && (
                <>
                  {paymentLoader ? (
                    <PulseLoader size={11} color={colorWhite} />
                  ) : (
                    <>
                      {!toAddress && (
                        <Link
                          onClick={() => {
                            ReCreatePayment(selectedtransaction);
                          }}
                          to={void 0}
                          className="simple-link purple"
                        >
                          Create Payment
                        </Link>
                      )}
                    </>
                  )}
                </>
              )}
              <div className="spacer-10" />
            </Col>

            <Col xl="12" lg="12" md="12" className="text-center">
              {/* {
                (selectedtransaction?.to_wallet_address || ExpireyPayment) &&
                <CountdownTimer Valid_Date={selectedtransaction?.expiry_date ?? ExpireyPayment} />
              } */}
              {(selectedtransaction?.to_wallet_address || toAddress) && (
                <QRCode
                  value={selectedtransaction?.to_wallet_address ?? toAddress}
                  // logoImage={`${CurrencyImageUrl}${selectLogo?.logo_url}`}
                  padding={0}
                  size={200}
                  logoOpacity={1}
                  logoWidth={50}
                />
              )}

              <div className="spacer-20" />
            </Col>
            <Col xl="12" lg="12" md="12" className="text-center">
              <ul className="my-token-list w-100">
                <li>
                  <p className="m-0">Tokens Ordered:</p>
                  <p className="m-0">
                    <b className="notranslate">
                      {" "}
                      {parseFloat(selectedtransaction?.token_purchased)
                        .toFixed(2)
                        ?.toString()
                        .replace(CommasRegex, ",") ?? 0}{" "}
                    </b>
                    $NDFT
                  </p>
                </li>
                <li>
                  <p className="m-0">Purchase Bonus:</p>
                  <p className="m-0">
                    <b className="notranslate">
                      {" "}
                      {parseFloat(selectedtransaction?.bonus_token)
                        .toFixed(2)
                        ?.toString()
                        .replace(CommasRegex, ",") ?? 0}{" "}
                    </b>
                    $NDFT
                  </p>
                </li>
              </ul>
              <div className="spacer-20" />
              <h4 className="chakra">
                <b>
                  <img src={arrowleft} alt="Arrow Left" /> You Get:{" "}
                  <img src={arrowright} alt="Arrow Right" />
                </b>
              </h4>
              <h3 className=" token-text-cntnr notranslate chakra">
                <img src={iconlogo} alt="ALGo Token" className="mx-2" />
                <span>
                  {parseFloat(selectedtransaction?.token_purchased)
                    .toFixed(2)
                    ?.toString()
                    .replace(CommasRegex, ",") ?? 0}
                  <span className="font-weight-normal ml-2 f-18 lightgrey-color Titillium">
                    $NDFT
                  </span>
                </span>
                <span className="mx-3 f-18">≈</span>
                <span>
                  <img src={iconusdc} alt="USDC Token" className="mx-2" />
                  {selectedtransaction?.amountafterfee}
                  <span className="font-weight-normal ml-2 f-18 lightgrey-color Titillium">
                    {selectedtransaction?.selected_crypto?.toUpperCase()}{" "}
                  </span>
                </span>
              </h3>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {/* make Payment Modal Ends Here */}

      {/* Sell Modal Starts Here */}
      <Modal
        size="md"
        show={false}
        centered
        // onHide={() => setloginShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="log-panel">
          <Button className="close-btn" onClick={() => {}}>
            <i className="fa fa-close" />
          </Button>
          <h3>Select BlockChain</h3>
          <p>
            To log in please use the method you used during the initial account
            opening process
          </p>
          <p>Connected Address</p>

          <p>{address}</p>

          <div className="d-flex justify-content-between w-100 mt-5">
            <Button
              onClick={() => {
                setConnectModal(false);
                setSelectedChain("ETH");
              }}
              className="reg-btn br-radius trans w-48"
            >
              <img src={coineth} alt="ETH" /> Buy With ETH
            </Button>

            <Button
              onClick={() => {
                setConnectModal(false);
                setSelectedChain(Network.USDT);
              }}
              className="reg-btn br-radius trans w-48"
            >
              <img src={coinusdt} alt="USDT" /> Buy With USDT
            </Button>
          </div>
          <div className="d-flex justify-content-between w-100 mt-5">
            <Button
              onClick={() => {
                setConnectModal(false);
                setSelectedChain(Network.USDC);
              }}
              className="reg-btn br-radius trans w-48"
            >
              <img src={coinusdc} alt="USDC" /> Buy With USDC
            </Button>

            <Button
              onClick={() => {
                setConnectModal(false);
                setSelectedChain(Network.BUSD);
              }}
              className="reg-btn w-48 br-radius trans"
            >
              <img src={coinbusd} alt="BUSD" /> Buy With BUSD
            </Button>
          </div>
          <div className="d-flex justify-content-between w-100 mt-5">
            <Button
              onClick={() => {
                setConnectModal(false);
                setSelectedChain("BNB");
              }}
              className="reg-btn br-radius trans w-48"
            >
              <img src={coinbnb} alt="BNB" /> Buy With BNB
            </Button>

            <Button
              onClick={() => {
                setConnectModal(false);
                setSelectedChain("OtherCurrency");
              }}
              className="reg-btn br-radius trans w-48"
            >
              <img src={coincrypto} alt="Crypto" /> Buy With Other Crypto
            </Button>
          </div>

          <span>© 2024 All Rights Reserved</span>
        </Modal.Body>
      </Modal>
      {/* Sell Modal Ends Here */}
    </>
  );
}

export default WalletTransaction;
