import { useEffect, useState } from "react";
import sanityClient from "../Api/sanity-client";
import { useParams } from "react-router-dom";
import {Container, Row, Col, Image, Card, Button} from "react-bootstrap";
import { PortableText } from '@portabletext/react';
import { Helmet } from "react-helmet";

const BlogDetail = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [isLoading, setLoading] = useState(true); // Adding loading state

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'post' && slug.current == '${slug}']{
              title,
              slug,
              imgAlt,
              secondImgAlt,
              metaTitle,
              metaDescription,
              author->{
              name
              },
              category->{
                name
              },
              publishedAt,
              mainImage{
                  asset->{
                  _id,
                  url
                }
              },
              middleImage{
                  asset->{
                  _id,
                  url
                }
              },
              body,
              secondBody
          }`
      )
      .then((data) => {
        setPost(data[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [slug]);


    const CTAComponent = {
        types: {
            callToAction: ({value, isInline}) =>
                isInline ? (
                    <a href={value.url}>{value.text}</a>
                ) : (
                    <Button>{value.text}</Button>
                ),
        },
        }

  if (isLoading) {
    return (
      <section
        className="giveaway-pnl container-fluid"
        style={{
          minHeight: "100vh",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "40px",
        }}
      >
        Loading blog...
      </section>
    );
  }

  if (!post) {
    return (
      <section
        className="giveaway-pnl container-fluid"
        style={{
          minHeight: "100vh",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "40px",
        }}
      >
        404: Blog not found
      </section>
    );
  }

  console.log(post);

  return (
    <>
      <Helmet>
        <title>{post.metaTitle ? post.metaTitle : "Blog"} | Algo Tech</title>
        <meta
          name="description"
          content={`${
            post.metaDescription ? post.metaDescription : "Blog"
          } | Algo Tech`}
        />
      </Helmet>

      <section className="giveaway-pnl container-fluid">
        <Container className="blog-post-detail">
          <Row className="justify-content-md-center">
            <Col md={8}>
                <Image
                    src={post?.mainImage?.asset?.url}
                    alt={post.imgAlt ?? "Blog Image"}
                    fluid
                    style={{marginTop: "20px", marginBottom: "20px"}}
                />
              <Card
                className="shadow-lg p-3 mb-5 rounded"
                style={{
                  backgroundColor: "rgba(106, 13, 173, 0.2)",
                  backdropFilter: "blur(10px)",
                }}
              >
                <Card.Header>
                  <Card.Title
                    style={{
                      fontSize: "44px",
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {post.title}
                  </Card.Title>
                  <Card.Title
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      color: "#FFFFFF",
                      background:
                        "linear-gradient(to right, rgb(158, 142, 244) 0%, rgb(47, 24, 168) 98%)",
                      borderRadius: "6px",
                      display: "inline-block",
                      padding: "4px",
                    }}
                  >
                    {post.category.name}
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontStyle: "italic",
                      display: "flex",
                      justifyContent: "space-between",
                      color: "darkgray",
                    }}
                  >
                    <div>
                      <strong>Published on:</strong>{" "}
                      {new Date(post.publishedAt).toLocaleDateString()}
                    </div>
                    <div>by {post.author.name}</div>
                  </Card.Text>
                </Card.Header>
              </Card>

              <div className="mt-3 text-left"><PortableText value={post.body} components={CTAComponent}  />
              </div>
                {post.middleImage && (
                    <Image
                        src={post.middleImage.asset.url}
                        alt={post.secondImgAlt ?? "Middle Image"}
                        fluid
                        className="mt-3"
                    />
                )}

                {post.secondBody && <div className="mt-3 text-left"><PortableText value={post.secondBody} components={CTAComponent}  /></div>}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default BlogDetail;
