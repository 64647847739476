import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import arrowleft from "../Assets/img/Icons/icon-arrow-1.png";
import arrowright from "../Assets/img/Icons/icon-arrow-2.png";
import sanityClient from "../Api/sanity-client";
import { Helmet } from "react-helmet";

const Blogs = () => {
  const [blogsList, setBlogsList] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'post']{
            title,
            slug,
            imgAlt,
            author->{
            name
            },
            category->{
            name
            },
            publishedAt,
            mainImage{
                asset->{
                _id,
                url
              }
            },
            body
        }`
      )
      .then((data) => setBlogsList(data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <Helmet>
        <title>Blogs - Algo Tech</title>
        <meta name="description" content="Blogs - Algo Tech" />
      </Helmet>

      <section className="blog-page container-fluid">
        <Container>
          <div className="spacer-10"/>
          <h3 className="chakra">
          <img src={arrowleft} alt="Arrow Left"/> {' '}Keep up with the{" "}
            <b className="color">latest in tech and crypto</b> {' '}<img src={arrowright} alt="Arrow Right"/>
          </h3>
          <div className="spacer-30"/>
          {blogsList.length === 0 ? (
            <section
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "40px",
              }}
            >
              No blogs found
            </section>
          ) : (
            <Row xs={1} md={2} lg={3} style={{ rowGap: "30px" }}>
              {blogsList.map((item, i) => (
                <Col key={i}>
                  <Card className="blog-post-card">
                    <Card.Img
                      alt={item.imgAlt ?? "Blog Image"}
                      variant="top"
                      src={item?.mainImage?.asset?.url}
                      style={{
                        maxHeight: "200px",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />

                    <Card.Body>
                      <Card.Title
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          color: "#FFFFFF",
                          background:
                            " linear-gradient(to right,  #FFB800 0%,#FF2E00 100%)",
                          borderRadius: "6px",
                          display: "inline-block",
                          padding: "4px",
                        }}
                      >
                        {item.category.name}
                      </Card.Title>
                      <Card.Title
                        style={{
                          fontWeight: "bold",
                          fontSize: "18px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          color: "#FFFFFF",
                        }}
                      >
                        {item.title}
                      </Card.Title>
                      <Card.Title
                        style={{
                          fontSize: "16px",
                          paddingLeft: "10px",
                          color: "#dcdcdc",
                        }}
                      >
                        by {item.author.name} •{" "}
                        {new Date(item.publishedAt).toLocaleDateString(
                          "en-US",
                          {
                            month: "short",
                            day: "numeric",
                            year: "numeric",
                          }
                        )}
                      </Card.Title>
                      <div className="spacer-10"/>
                      <Link
                        className="reg-btn br-radius trans"
                        to={`/Blog/${item.slug.current}`}
                        style={{
                          padding: "8px",
                          fontSize: "15px",
                          color: "white",
                        }}
                      >
                        Continue Reading
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              ))}

              {/* ))} */}
            </Row>
          )}
        </Container>
      </section>
    </>
  );
};

export default Blogs;
