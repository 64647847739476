import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import Joinpresalenow from "../Components/Joinpresalenow";
import howtobuybg from "../Assets/img/Bg/bg-2.png";
import step1 from "../Assets/img/Step/step-1.png";
import step2 from "../Assets/img/Step/step-2.png";
import step3 from "../Assets/img/Step/step-3.png";
import step4 from "../Assets/img/Step/step-4.png";
function HowToBuy({setSignupShow,setloginShow}) {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
    setSignupShow(false)
  }, []);

  return (
    <>
      {/* Hot To Buy Starts Here */}
      <section className="inner-page How-to-buy-page container-fluid">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${howtobuybg})` }}
        />
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" className="text-center">
                <h1>
                  How to Buy <span>$NDFT</span>
                </h1>
                <div className="spacer-20"/>
                <h4>We are currently in the Presale of the $NDFT Token.</h4>
                <h4>
                  <Link onClick={()=>{ setloginShow(true)} } to={void(0)}>Log-In</Link> or{" "}
                  <Link  onClick={()=>{ setSignupShow(true)} } to={void(0)}>Create an account</Link> to buy.
                </h4>
                <div className="spacer-40"/>
              </Col>
              <Col xl={{ span: 8, offset: 2 }}>
                <div className="spacer-50"/>
                <ul className="buy-step-list">
                  <li>
                    <h3>Step 1</h3>
                    <p>
                      To buy $NDFT tokens, create an account to track your
                      purchases and view your $NDFT holdings.
                    </p>
                    <img src={step1} alt="Step 1" />
                  </li>
                  <li>
                    <h3>Step 2</h3>
                    <p>
                      After registering, click "Purchase Tokens", input your
                      desired amount in USD and select your preferred
                      cryptocurrency. Confirm your order by clicking "Pay using
                      cryptocurrency"
                    </p>
                    <img src={step2} alt="Step 1" />
                  </li>
                  <li>
                    <h3>Step 3</h3>
                    <p>
                      After confirming your order, a payment wallet address will
                      be provided (valid for 24 hours only).
                    </p>
                    <img src={step3} alt="Step 1" />
                  </li>
                  <li>
                    <h3>Step 4</h3>
                    <p>
                      Send payment to the provided address using your chosen
                      cryptocurrency. After completing the payment, your
                      customer account will reflect your purchase and number of
                      tokens bought. At the presale's end, you will receive
                      airdropped tokens, including bonus tokens.
                    </p>
                    <img src={step4} alt="Step 1" />
                  </li>
                </ul>
                <div className="spacer-50"/>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Hot To Buy Ends Here */}

      {/* Join Pre Sale Now Panel */}
      <Joinpresalenow  setSignupShow={setSignupShow}/>
      {/* Join Pre Sale Now Panel */}
    </>
  );
}
export default HowToBuy;
