import React from "react";
import icontether from "../Assets/img/Icons/icon.png";
import minilogo from "../Assets/img/Icons/icon-logo.png";
import iconetherium from "../Assets/img/Icons/icon-etherium.png";
import chart from "../Assets/img/chart.png";
import arrowleft from "../Assets/img/Icons/icon-arrow-1.png";
import arrowright from "../Assets/img/Icons/icon-arrow-2.png";
function Tokenomics() {
  return (
    <>
      <h2>
        <img src={arrowleft} alt="Arrow Left" /> Tokenomics{" "}
        <img src={arrowright} alt="Arrow Right" />
      </h2>
      <div className="spacer-50" />
      <img src={chart} alt="Chart" />
      <div className="spacer-50" />
      <ul className="crypto-supply-list">
        <li>
          <h6>Total Token Supply</h6>
          <h3>
            <img src={minilogo} alt="Logo" /> 350,000,000
          </h3>
        </li>
        <li>
          <h6>Token network</h6>
          <h3>
            <img src={iconetherium} alt="Etherium" /> ERC-20
          </h3>
        </li>
        <li>
          <h6>Estimated Launch Price</h6>
          <h3>
            <img src={icontether} alt="Tether" /> 0.15
          </h3>
        </li>
      </ul>
    </>
  );
}
export default Tokenomics;
