import React from "react";
import post1 from "../Assets/img/Bg/numbers1.png";
import post2 from "../Assets/img/Bg/numbers2.png";
import post4 from "../Assets/img/Bg/numbers3.png";
function IncomeList() {
  return (
    <>
      <ul className="incom-list">
        <li>
          <div className="img-pnl">
            <img src={post1} alt="post" />
          </div>
          <div className="txt-pnl">
            <h4>Hold NDF</h4>
            <p>
              Become a part of the New Dawn Finance presale and position
              yourself to make generational gains. Holders will be automatically
              entitled to receive fund profits.
            </p>
          </div>
        </li>
        <li>
          <div className="img-pnl">
            <img src={post2} alt="post" />
          </div>
          <div className="txt-pnl">
            <h4>Experience AI-Based Allocation</h4>
            <p>
              Our advanced AI determines the best trades in the market based on
              over 150,000 datapoints. Trade options include high-frequency,
              algorithmic trading, and social trend trading to generate profits.
            </p>
          </div>
        </li>
        <li>
          <div className="img-pnl">
            <img src={post4} alt="post" />
          </div>
          <div className="txt-pnl">
            <h4>Watch Your Portfolio Grow</h4>
            <p>
              With an exceptional APY of over 100% on your holdings, our
              investors experience market-beating returns with a compounding
              effect. Expand capital impact with further staking and DeFi
              applications.
            </p>
          </div>
        </li>
      </ul>
    </>
  );
}
export default IncomeList;
