import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import Logo from "../Assets/img/Logo/footer-logo.png";
import twitter from "../Assets/img/Icons/icon-twitter.png";
import instagram from "../Assets/img/Icons/icon-instagram.png";
import facebook from "../Assets/img/Icons/icon-facebook.png";
import youtube from "../Assets/img/Icons/icon-youtube.png";
import tiktok from "../Assets/img/Icons/icon-tik-tok.png";
import {
  DiscordIcon,
  InstagramUrl,
  WhitePaperLink,
  teleGramUrl,
  twitterIcon,
} from "../Constants";

function Footer({
  scrollToPlateform,
  scrollToIndexes,
  RewardToFeatured,
  TokenToFeatured,
  FaqToFeatured,
  RoadToFeatured,
}) {
  return (
    <>
      <footer className="container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="4" lg="3" md="12" sm="12">
                <Link to="/">
                  <img src={Logo} alt="Logo" />
                </Link>
              </Col>
              <Col xl="4" lg="6" md="12" sm="12">
                <ul className="footer-list">
                  <li> 
                    <Link to={"/"} onClick={() => scrollToPlateform()}>Platform</Link>
                  </li>
                  <li>
                    <Link to={"/"} onClick={() => scrollToIndexes()}>Indexes</Link>
                  </li>
                  <li>
                    <Link to={"/"}>Guides</Link>
                  </li>
                  <li>
                    <Link to="about">About</Link>
                  </li>
                </ul>
              </Col>
              <Col xl="4" lg="3" md="12" sm="12">
                <ul className="footer-social-list">
                  <li>
                    <Link target="_blank" to={twitterIcon}>
                      <img src={instagram} alt="instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to={twitterIcon}>
                      <img src={twitter} alt="twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to={DiscordIcon}>
                      <img src={facebook} alt="facebook" />
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to={InstagramUrl}>
                      <img src={youtube} alt="youtube" />
                    </Link>
                  </li>
                  <li>
                    <Link target="_blank" to={teleGramUrl}>
                      <img src={tiktok} alt="Tik Tok" />
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Row>
        <ul className="bottom-list">
          <li>
            <Link
              to="/TermsConditions"
              className={`${
                useLocation().pathname == "/TermsConditions" ? "active" : ""
              }`}
            >
              Terms & conditions
            </Link>
          </li>
          <li>
            <Link
              to="/PrivacyPolicy"
              className={`${
                useLocation().pathname == "/PrivacyPolicy" ? "active" : ""
              }`}
            >
              Privacy Policy
            </Link>
          </li>
          <li>© 2024 New Dawn Finance. All Right Reserved.</li>
        </ul>
      </footer>
    </>
  );
}
export default Footer;
