import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Container, Col, Button, Form } from "react-bootstrap";
import NavBarDash from "../../Components/NavBarDash";
import iconlogo from "../../Assets/img/Logo/icon-logo.png";
import iconusdc from "../../Assets/img/admin-icons/icon-udsc.png";
import iconpercentage from "../../Assets/img/admin-icons/icon-percentage.png";
import arrowleft from "../../Assets/img/Icons/icon-arrow-1.png";
import arrowright from "../../Assets/img/Icons/icon-arrow-2.png";
import qrcode from "../../Assets/img/q-r-code.png";
import iconcopy from "../../Assets/img/admin-icons/icon-copy.png";
import ChatButton from "../../Components/ChatButton";
import {
  CheckPromoValidity,
  CreatePayment,
  GetAllAvaliableCurrencies,
  GetAllAvaliableCurrenciesWithLogo,
  GetSelectedCurrencyPrice,
  MakeTransaction,
  UpdateTransaction,
  getUserDetail,
} from "../../Api";
import {
  CurrenciesArray,
  CurrencyImageUrl,
  NumberRegex,
  CurrenicesArray,
  colorRed,
  colorWhite,
  CommasRegex,
} from "../../Constants";
import { BounceLoader, PulseLoader } from "react-spinners";
import Select from "react-select";
import UserContest from "../../ContextAPI/UserContest";
import { Notifications } from "../../Components/Toast";
import { QRCode } from "react-qrcode-logo";
import CountdownTimer from "../../Components/ExpireyDate";
import { debounce } from "lodash"; // Import the debounce function from lodash or use your own debounce implementation
import { CopyToClipboardButton } from "../../Constants/utils";

function PurchaseToken({ setShowFooter, setShowNavBar }) {
  // Hide the footer when the Login component mounts
  const {
    CurrentStage,
    TotalSaleInStage,
    OverAllSaleToken,
    SetPromocode,
    SelectedCurrency,
    Selectedvalue,
    Promocode,
    UserDetail,
    SetUserDetail,
  } = useContext(UserContest);
  useEffect(() => {
    setShowFooter(false);
    setShowNavBar(false);
  }, [setShowFooter, setShowNavBar]);
  const [isFormVisible, setFormVisibility] = useState(false);
  const [promoValidation, setPromoValidation] = useState();
  const [promotionBonus, setPromotionBonus] = useState();
  const [loader, setloader] = useState(false);
  const [isOrderPaymentVisible, setOrderPaymentVisibility] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState({
    label: "BTC",
    value: "btc",
  });
  const navigate = useNavigate();

  const [currencies, setCurrencies] = useState([]);
  const [currenciesWithLogo, setCurrenciesWithLogo] = useState([]);
  const [selectLogo, setSelectedLogo] = useState();
  const [amount, setAmount] = useState();
  const [amountInCurrency, setAmountInCurrency] = useState(3);
  const [selectedCurrencyPrice, setselectedCurrencyPrice] = useState(0);
  const [agreed, setagreed] = useState(false);
  const [transactionHash, setTransactionHash] = useState();
  const [toAddress, setToAddress] = useState();
  const [AmountToSend, setAmountToSend] = useState();
  const [ExpireyPayment, setExpireyPayment] = useState();
  const [PromoCodetext, setPromoCodetext] = useState("");
  const [PromoCodetextchange, sePromoCodetextchange] = useState("");
  const [ExtraBonus, setExtraBonus] = useState(false);
  const currencyNameBNB = "bnbbsc";
  useEffect(() => {
    if (SelectedCurrency && Selectedvalue) {
      setAmount(Selectedvalue);
      setSelectedCurrency(SelectedCurrency);
    }
    GetAllAvaliableCurrencies()
      .then((currencies) => {
        // console.log(currencies)
        setCurrencies(currencies.currencies);
      })
      .catch(() => {});
    GetAllAvaliableCurrenciesWithLogo()
      .then((currencies) => {
        setCurrenciesWithLogo(currencies);
        // console.log(currencies, 'currencies')
      })
      .catch(() => {});
  }, []);
  const calculateTokens = () => {
    const tokensPerDollar = 1 / CurrentStage?.price_of_token;
    const numberOfTokens = tokensPerDollar * (amount ?? 0);
    return numberOfTokens;
  };
  const tokens = calculateTokens();
  const calculateBTC = (amount, selectedCurrencyPrice) => {
    const btcPerUSD = (amount ?? 0) * selectedCurrencyPrice;
    return btcPerUSD.toFixed(5);
  };

  // Define state for btcReceived
  const [btcReceived, setBtcReceived] = useState("");
  const [loadingSelectedPrice, setLoadingSelectedPrice] = useState(false);

  useEffect(() => {
    setSelectedLogo(
      currenciesWithLogo?.find((item) => item.ticker === selectedCurrency.value)
    );
    setLoadingSelectedPrice(true);
    GetSelectedCurrencyPrice(
      selectedCurrency?.value === "bnb"
        ? currencyNameBNB
        : selectedCurrency?.value
    )
      .then((price) => {
        setselectedCurrencyPrice(price);
        // Calculate btcReceived and update its state
        const calculatedBtcReceived = calculateBTC(amount, price);
        setBtcReceived(calculatedBtcReceived);
        setLoadingSelectedPrice(false);
      })
      .catch(() => {
        // Handle error
      });
  }, [selectedCurrency, currenciesWithLogo, amount]);

  const toggleFormVisibility = () => {
    setFormVisibility(!isFormVisible);
  };
  const toggleOrderPaymentVisibility = () => {
    if (loadingSelectedPrice) return;
    if (
      parseInt(CurrentStage?.total_limit) - parseInt(TotalSaleInStage ?? 0) <
      tokens
    ) {
      Notifications("error", "Token value increased from limit");
      return;
    }
    setloader(true);
    if (amount > 0 && agreed) {
      const Body = {
        account_id: UserDetail?.id,
        token_purchased: tokens,
        amount_in_crypto: btcReceived,
        amount_in_dollars: amount,
        selected_crypto: selectedCurrency.value,
        stage_id: CurrentStage?.id,
        type: "Purchase",
        bonus_token: tokens * (bonus ?? 0),
        promotionText: PromoCodetext,
        tokenPrice: CurrentStage?.price_of_token,
      };
      MakeTransaction(Body)
        .then((data) => {
          CreatePayment({
            amount: amount,
            selectedCurrency: selectedCurrency.value,
            transaction_number: data[0].transaction_number,
            description: btoa(
              JSON.stringify({
                stage_id: CurrentStage?.id,
                email: UserDetail?.email,
                created_from_referral: UserDetail?.created_from_referral,
                referral_used: UserDetail?.referral_used,
              })
            ),
          })
            .then((createdPayment) => {
              getUserDetail()
                .then((detail) => {
                  // console.log(detail ,'getUserDetail' )
                  SetUserDetail(detail);
                })
                .catch((data) => {
                  // navigate('/')
                });
              setloader(false);
              setToAddress(createdPayment.pay_address);
              setAmountToSend(createdPayment.pay_amount);
              setExpireyPayment(createdPayment.expiration_estimate_date);
              let updateTransaction = {
                expiry_date: createdPayment.expiration_estimate_date,
                payment_id: createdPayment.payment_id,
                toAddress: createdPayment.pay_address,
                transaction_number: data[0].transaction_number,
                payment_created_date: createdPayment.created_at,
                AmountToSend: createdPayment.pay_amount,
              };
              UpdateTransaction(updateTransaction)
                .then(() => {})
                .catch(() => {
                  setloader(false);
                });
            })
            .catch((data) => {
              if (data?.message)
                Notifications("error", data?.message + "error code 1");

              Notifications("error", data?.response?.data?.message);

              setloader(false);
            });
          setTransactionHash(data[0].transaction_number);
          setOrderPaymentVisibility(!isOrderPaymentVisible);
        })
        .catch(() => {
          setloader(false);
          Notifications("error", "Some error occured during purchase");
        });
    } else {
      setloader(false);
      if (!amount) Notifications("error", "Please Enter Amount");
      else if (!agreed)
        Notifications("error", "Please check  term and conditions");
    }
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "20px",
      // height:'42px',
      // innerHeight:'42px',
      outerHeight: "42px",
      border: "1px solid #ccc",
      boxShadow: "none",
    }),
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
  };
  // const options = currencies.map((item) => ({
  //   label: item.currency,
  //   value: item.currency,
  // }));
  const options = CurrenicesArray.map((item) => ({
    label: item.label.toUpperCase(),
    image: item.image,
    value: item.label,
  }));

  function calculateBonus(depositAmount) {
    let bonusPercentage = 0;

    if (depositAmount >= 1000.0 && depositAmount <= 5000) {
      bonusPercentage = 0.05;
    } else if (depositAmount >= 5000.0 && depositAmount <= 25000) {
      bonusPercentage = 0.1;
    } else if (depositAmount >= 25000.0 && depositAmount <= 100000) {
      bonusPercentage = 0.125;
    } else if (depositAmount >= 100000) {
      bonusPercentage = 0.15;
    }

    return bonusPercentage;
  }

  const bonus = calculateBonus(amount);

  useEffect(() => {
    if (UserDetail?.created_from_referral && !UserDetail?.referral_used) {
      setExtraBonus(true);
    }
    // console.log(UserDetail)
    if (PromoCodetext) {
      CheckPromoValidity(PromoCodetext, UserDetail?.id)
        .then((item) => {
          if (item?.message) setPromoValidation(item?.message);
          else setPromoValidation();
          if (item?.bonus) {
            setPromotionBonus(item?.bonus);
          }
        })
        .catch((item) => {
          // console.log(item.request.response)
        });
    }
  }, [PromoCodetext, UserDetail]);

  const debouncedClickHandler = debounce(() => {
    if (!loader) {
      toggleOrderPaymentVisibility();
    }
  }, 1000);

  return (
    <>
      <NavBarDash />
      <ChatButton />
      <section className="admin-panel container-fluid">
        <Row>
          <Container>
            <Row>
              <Col
                xl={{ span: 8, offset: 2 }}
                className={`step-section  ${
                  isOrderPaymentVisible ? "" : "block"
                }`}
              >
                <div className="blue-post">
                  <div className="text-center">
                    <h3 className="chakra">
                      <img src={arrowleft} alt="Arrow Left" /> Step 1{" "}
                      <img src={arrowright} alt="Arrow Right" />
                    </h3>
                  </div>
                  <div className="spacer-10" />
                  <p className="font-weight-normal f-18">
                    Enter the amount in USD you want spend to purchase $NDFT
                    tokens.
                  </p>{" "}
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Amount in USD </Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          // Allow only numbers from 0 to 9, an optional single dot, and an empty string (for backspace)
                          if (/^\d*\.?\d*$/.test(inputValue)) {
                            setAmount(inputValue);
                          }
                        }}
                        onKeyPress={(e) => {
                          // Allow only numbers from 0 to 9, an optional single dot, and backspace
                          const keyCode = e.keyCode || e.which;
                          const keyValue = String.fromCharCode(keyCode);
                          if (
                            !NumberRegex.test(keyValue) &&
                            keyValue !== "." &&
                            keyCode !== 8
                          ) {
                            e.preventDefault();
                          }
                        }}
                        value={amount}
                        className="input-dollar"
                        placeholder="Amount in USD"
                      />
                    </Form.Group>
                    {/* {
                          parseInt(amount) < CurrentStage.minimumpurchase
                          &&
                          <p style={{ color: colorRed }}>Minimum Purchase of token is
                            ${CurrentStage.minimumpurchase}

                          </p>

                        } */}

                    {parseInt(amount) >
                    parseInt(CurrentStage?.maximumpurchase) ? (
                      <p style={{ color: colorRed }}>
                        Maximum Purchase of token is $
                        {parseInt(CurrentStage?.maximumpurchase)}
                      </p>
                    ) : (
                      <>
                        {parseInt(CurrentStage?.total_limit) -
                          (parseInt(TotalSaleInStage) || 0) <
                          tokens && (
                          <p style={{ color: colorRed }}>
                            Token Limit in Current Stage Exceeds:
                          </p>
                        )}
                      </>
                    )}
                  </Form>
                  {/* Talha */}
                  <ul className="bonus-percentage-list">
                    <li>
                      <h3>
                        <span>Bonus</span>
                        5%
                      </h3>
                      <p>
                        Deposit
                        <span>250.00</span>
                        USD to <span>1999.99</span> USD
                      </p>
                    </li>
                    <li>
                      <h3>
                        <span>Bonus</span>
                        10%
                      </h3>
                      <p>
                        Deposit
                        <span>200.00</span>
                        USD to <span>4999.99</span> USD
                      </p>
                    </li>
                    <li>
                      <h3>
                        <span>Bonus</span>
                        20%
                      </h3>
                      <p>
                        Deposit over
                        <span>500.00</span>
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="blue-post">
                  <div className="text-center">
                    <h3 className="chakra">
                      <img src={arrowleft} alt="Arrow Left" /> Step 2{" "}
                      <img src={arrowright} alt="Arrow Right" />
                    </h3>
                  </div>
                  <div className="spacer-10" />
                  <p className="font-weight-normal f-18">
                    Select your payment currency
                  </p>
                  <div className="spacer-10" />
                  <Form>
                    <Row>
                      <Col xl="7" lg="7" md="7" sm="12">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>You Send</Form.Label>
                          <Form.Control
                            type="number"
                            disabled={true}
                            value={btcReceived}
                            autoComplete="new-email"
                            className="input-dollar"
                            placeholder="Enter Your Email"
                          />
                        </Form.Group>
                      </Col>
                      <Col xl="5" lg="5" md="5" sm="12">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Select a currency</Form.Label>
                          <Form.Select
                            onChange={(e) => {
                              if (e.target.value !== "DUMP")
                                setSelectedCurrency(
                                  options.find(
                                    (item) => item.value === e.target.value
                                  )
                                );
                            }}
                            className="form-control "
                            aria-label="Default select example"
                            value={selectedCurrency.value}
                          >
                            <option value="DUMP">-- Select Token --</option>
                            {options.map((item, index) => {
                              return (
                                <option value={item.value} key={index}>
                                  {/* <img src={item.image} style={{ width: '50px' }} alt={item.label} /> */}
                                  {item.label.toUpperCase()}
                                </option>
                              );
                            })}
                          </Form.Select>

                          {/* <Form.Select

                            onChange={(e) => {
                              setSelectedCurrency(e.target.value)
                            }}
                            className="form-control token-select"
                            aria-label="Default select example"

                          >
                            <option>-- Select Currency --</option>
                            {
                              CurrenciesArray.map((item, index) => {
                                return (

                                  <option value={item.value}>{item.dataLabel}</option>
                                )
                              })
                            }

                          </Form.Select> */}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>

                  {
                    <Button
                      className={`simple-link purple  `}
                      onClick={() => {
                        if (PromoCodetext?.length > 1) {
                          setPromoCodetext(null);
                          sePromoCodetextchange("");
                          setPromoValidation("");
                        } else {
                          toggleFormVisibility();
                        }
                      }}
                    >
                      {PromoCodetext?.length > 1
                        ? "remove  Promo Code"
                        : "Add  Promo Code"}
                    </Button>
                  }
                  {PromoCodetext?.length > 1 && (
                    <>
                      <p>PromoCode : {PromoCodetext}</p>
                      <p style={{ color: colorRed }}>{promoValidation}</p>
                    </>
                  )}
                  <div className="spacer-10" />
                  <div className={`full-div ${isFormVisible ? "" : "hidden"}`}>
                    <Row>
                      <Col xl="8" lg="8" md="8" sm="12">
                        <Form>
                          <div
                            className="input-cntnr"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              className="input-promocode"
                              autoComplete="off"
                              type="text"
                              value={PromoCodetextchange}
                              onChange={(e) => {
                                sePromoCodetextchange(e.target.value);
                              }}
                              placeholder="Enter PROMO CODE here"
                            />
                          </div>
                        </Form>
                      </Col>
                      <Col xl="4" lg="4" md="4" sm="12">
                        <Link
                          className="reg-btn w-100 br-radius empty text-center small"
                          to={void 0}
                          onClick={() => {
                            toggleFormVisibility();
                            setPromoCodetext(PromoCodetextchange);
                          }}
                        >
                          Apply Promo code
                        </Link>
                      </Col>
                    </Row>
                  </div>
                  <div className="spacer-20" />
                  <ul className="my-token-list w-100">
                    <li>
                      <p className="m-0">Tokens Ordered:</p>
                      <p className="m-0 ">
                        <b className="notranslate">
                          {loadingSelectedPrice ? (
                            <PulseLoader size={11} color="white" />
                          ) : (
                            <>
                              {" "}
                              {parseFloat(tokens)
                                .toFixed(2)
                                ?.toString()
                                .replace(CommasRegex, ",") ?? 0}
                            </>
                          )}
                          <span className="lightgrey-color ml-2 font-weight-normal">
                            $NDFT
                          </span>
                        </b>
                      </p>
                    </li>
                    <li>
                      <p className="m-0">Purchase Bonus:</p>
                      <p className="m-0">
                        <b className="notranslate">
                          {loadingSelectedPrice ? (
                            <PulseLoader size={11} color="white" />
                          ) : (
                            <>
                              {parseFloat(tokens * bonus)
                                .toFixed(2)
                                ?.toString()
                                .replace(CommasRegex, ",") ?? 0}
                            </>
                          )}
                          <span className="lightgrey-color ml-2 font-weight-normal">
                            $NDFT
                          </span>
                        </b>
                      </p>
                    </li>
                    {ExtraBonus && (
                      <li>
                        <p className="m-0">Referral Bonus:</p>
                        <p className="m-0">
                          <b className="notranslate">
                            {loadingSelectedPrice ? (
                              <PulseLoader size={11} color="white" />
                            ) : (
                              <>{ExtraBonus ? tokens * 0.1 : 0}</>
                            )}
                            <span className="lightgrey-color ml-2 font-weight-normal">
                              $NDFT
                            </span>
                          </b>
                        </p>
                      </li>
                    )}
                    {promotionBonus && PromoCodetext && (
                      <li>
                        <p className="m-0">Promo Bonus:</p>
                        <p className="m-0">
                          <b className="notranslate">
                            {loadingSelectedPrice ? (
                              <PulseLoader size={11} color="white" />
                            ) : (
                              <>{tokens * (promotionBonus / 100)}</>
                            )}
                            <span className="lightgrey-color ml-2 font-weight-normal">
                              $NDFT
                            </span>
                          </b>
                        </p>
                      </li>
                    )}
                  </ul>
                  <div className="spacer-40" />
                  <div className="text-center">
                    <h4 className="chakra">
                      <b>
                        <img src={arrowleft} alt="Arrow Left" /> You Get:{" "}
                        <img src={arrowright} alt="Arrow Right" />
                      </b>
                    </h4>
                    <h3 className=" token-text-cntnr notranslate chakra">
                      <span>
                        <img className="mr-2" src={iconlogo} alt="ALGo Token" />
                        {loadingSelectedPrice ? (
                          <PulseLoader size={11} color="white" />
                        ) : (
                          <>
                            {parseFloat(tokens)
                              .toFixed(2)
                              ?.toString()
                              .replace(CommasRegex, ",") ?? 0}
                          </>
                        )}

                        <span className="font-weight-bold tillium ml-2 f-18">
                          $NDFT
                        </span>
                      </span>
                      <span className="mx-3 f-18">≈</span>
                      <span>
                        <img
                          className="mr-2"
                          style={{ width: "30px" }}
                          src={
                            selectLogo?.logo_url
                              ? `${CurrencyImageUrl}${selectLogo?.logo_url}`
                              : iconusdc
                          }
                          alt="USDC Token"
                        />
                        {loadingSelectedPrice ? (
                          <PulseLoader size={11} color="white" />
                        ) : (
                          <>{btcReceived}</>
                        )}

                        <span className="font-weight-bold tillium ml-2 f-18">
                          {selectedCurrency.value?.toUpperCase()}{" "}
                        </span>
                      </span>
                    </h3>
                  </div>
                </div>
                <div className="web-text-center">
                  <Form>
                    {["checkbox"].map((type) => (
                      <div key={`inline-${type}`}>
                        <Form.Check
                          className="whitecolor"
                          checked={agreed}
                          onChange={(e) => setagreed(e.target.checked)} // Corrected event handler
                          inline
                          label={
                            <>
                              I hereby agree to the{" "}
                              <Link
                                className="simple-link"
                                to="/TermsConditions"
                                target="_blank" // This opens the link in a new tab/window
                                rel="noopener noreferrer" // Recommended for security reasons
                              >
                                payment agreement and token sale terms *
                              </Link>
                            </>
                          }
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                        />
                      </div>
                    ))}
                  </Form>
                  <div className="spacer-20" />
                  <Button
                    className={`reg-btn br-radius letter-spacing-1 m-w-100 ${
                      isOrderPaymentVisible ? "" : "remove"
                    }`}
                    disabled={
                      loader ||
                      parseFloat(selectedCurrencyPrice) === 0 ||
                      promoValidation ||
                      parseFloat(amount) >
                        parseFloat(CurrentStage.maximumpurchase) ||
                      !amount ||
                      amount == 0
                    }
                    onClick={debouncedClickHandler}
                  >
                    {loader ? (
                      <PulseLoader size={11} color={colorWhite} />
                    ) : (
                      " Buy Token NOW"
                    )}
                  </Button>
                  <div className="spacer-20" />
                  <p className="m-0 f-18">
                    A processing fee may be charged by the payment gateway
                  </p>
                  {/* <p className="m-0">
                    <span style={{ color: "red" }}>
                      Note: We do not accept USDC for BASE payments. Please
                      utilize alternative methods.{" "}
                    </span>
                  </p> */}
                </div>
                <div className="spacer-30" />
              </Col>
            </Row>
            <Row>
              <Col
                xl={{ span: 8, offset: 2 }}
                className={`full-div ${isOrderPaymentVisible ? "" : "hidden"}`}
              >
                <>
                  <div className="text-center">
                    <h2 className=" font-weight-bolder">
                      <img src={arrowleft} alt="Arrow Left" /> Make your payment{" "}
                      <img src={arrowright} alt="Arrow Right" />
                    </h2>
                  </div>
                  <div className="spacer-20" />
                  <div className="blue-post p-0">
                    <div className="green-post">
                      <h6 className="m-0">
                        Your Order #{" "}
                        <span className="blue-color">{transactionHash}</span>{" "}
                        has been placed successfully!
                      </h6>
                    </div>
                    <div className="inner-padding">
                      <Row>
                        {/* <Button
                        className="close-btn"
                        onClick={() => {
                          navigate("/Transactions");
                        }}
                      >
                        <i className="fa fa-close"/>
                      </Button> */}

                        <Col xl="4" lg="4" md="12" className="text-center">
                          {/* <p className="m-0 white-color">
                            <b>
                              {toAddress && (
                                <CountdownTimer
                                  Valid_Date={`${ExpireyPayment}`}
                                />
                              )}
                            </b>
                          </p>
                          <div className="spacer-10" /> */}

                          <div className="spacer-5" />
                          {toAddress ? (
                            <QRCode
                              value={toAddress}
                              logoImage={`${CurrencyImageUrl}${selectLogo?.logo_url}`}
                              padding={0}
                              size={200}
                              logoOpacity={1}
                              logoWidth={50}
                            />
                          ) : (
                            <div className="center-allign">
                              <BounceLoader size={40} color={colorWhite} />
                            </div>
                          )}

                          <div className="spacer-20" />
                        </Col>
                        <Col xl="8" lg="8" md="12">
                          <p className="m-0">
                            <b>Amount</b>
                          </p>
                          <h4 className="font-weight-bold chakra">
                            <img
                              style={{ width: "30px" }}
                              src={
                                selectLogo?.logo_url
                                  ? `${CurrencyImageUrl}${selectLogo?.logo_url}`
                                  : iconusdc
                              }
                              alt="USDC Token"
                            />
                            {AmountToSend}
                            <span className="font-weight-normal mx-2 Titillium">
                              {selectedCurrency.value?.toUpperCase()}{" "}
                            </span>
                          </h4>
                          <div className="purple-post">
                            <p className="m-0 font-weight-bold">
                              To this address
                            </p>
                            <p className="font-weight-normal m-0 lightgrey-color addressTo">
                              {toAddress ?? (
                                <PulseLoader color={colorWhite} size={11} />
                              )}
                            </p>
                            {toAddress && (
                              <Link
                                onClick={() =>
                                  CopyToClipboardButton(
                                    toAddress,
                                    "Address Copied Successfully"
                                  )
                                }
                                className="simple-link purple"
                              >
                                <img src={iconcopy} alt="Copy" /> Copy Address
                              </Link>
                            )}
                          </div>
                        </Col>
                        <Col xl="12" lg="12" md="12" sm="12">
                          <ul className="my-token-list w-100">
                            <li>
                              <p className="m-0">Tokens Ordered:</p>
                              <p className="m-0">
                                {parseFloat(tokens)
                                  .toFixed(2)
                                  ?.toString()
                                  .replace(CommasRegex, ",") ?? 0}{" "}
                                <span className="lightgrey-color font-weight-normal">
                                  $NDFT
                                </span>
                              </p>
                            </li>
                            <li>
                              <p className="m-0">Purchase Bonus:</p>
                              <p className="m-0">
                                {parseFloat(tokens * bonus)
                                  .toFixed(2)
                                  ?.toString()
                                  .replace(CommasRegex, ",") ?? 0}{" "}
                                <span className="lightgrey-color font-weight-normal">
                                  $NDFT
                                </span>
                              </p>
                            </li>

                            {ExtraBonus && (
                              <li>
                                <p className="m-0">Referral Bonus:</p>
                                <p className="m-0">
                                  {ExtraBonus ? tokens * 0.1 : 0}
                                  <span className="lightgrey-color font-weight-normal">
                                    $NDFT
                                  </span>
                                </p>
                              </li>
                            )}
                            {promotionBonus && PromoCodetext && (
                              <li>
                                <p className="m-0">Promo Bonus:</p>
                                <p className="m-0">
                                  {tokens * (promotionBonus / 100)}
                                  <span className="lightgrey-color font-weight-normal">
                                    $NDFT
                                  </span>
                                </p>
                              </li>
                            )}
                          </ul>
                        </Col>
                        <div className="spacer-20" />
                        <Col xl="12" lg="12" md="12" className="text-center">
                          <h4 className="notranslate chakra">
                            <b>
                              <img src={arrowleft} alt="Arrow Left" /> You Get:{" "}
                              <img src={arrowright} alt="Arrow Right" />
                            </b>
                          </h4>
                          <h3 className="token-text-cntnr notranslate chakra">
                            <img
                              src={iconlogo}
                              alt="ALGo Token"
                              className="mx-2"
                            />
                            <span>
                              {parseFloat(tokens)
                                .toFixed(2)
                                ?.toString()
                                .replace(CommasRegex, ",") ?? 0}
                              <span className="font-weight-normal ml-2 f-18 lightgrey-color Titillium">
                                $NDFT
                              </span>
                            </span>
                            <span className="mx-3 f-18">≈</span>
                            <span>
                              <img
                                className="mr-2"
                                style={{ width: "30px" }}
                                src={
                                  selectLogo?.logo_url
                                    ? `${CurrencyImageUrl}${selectLogo?.logo_url}`
                                    : iconusdc
                                }
                                alt="USDC Token"
                              />
                              {AmountToSend}
                              <span className="font-weight-normal ml-2 f-18 lightgrey-color Titillium">
                                {selectedCurrency.value?.toUpperCase()}{" "}
                              </span>
                            </span>
                          </h3>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
    </>
  );
}
export default PurchaseToken;
