import React, { useContext, useEffect, useMemo } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Container,
  Col,
  Tab,
  Tabs,
  Form,
  Modal,
  Button,
} from "react-bootstrap";
import NavBarDash from "../../Components/NavBarDash";
import iconerror from "../../Assets/img/admin-icons/icon-error.png";
import iconsetting from "../../Assets/img/admin-icons/icon-setting.png";
import iconuser from "../../Assets/img/admin-icons/icon-user-5.png";
import iconlock from "../../Assets/img/admin-icons/icon-lock.png";
import iconcheck from "../../Assets/img/admin-icons/icon-bg-check.png";
import qrcode from "../../Assets/img/q-r-code.png";
import arrowleft from "../../Assets/img/Icons/icon-arrow-1.png";
import arrowright from "../../Assets/img/Icons/icon-arrow-2.png";
import iconcross from "../../Assets/img/admin-icons/icon-cross.png";
import iconcheckgreen from "../../Assets/img/admin-icons/icon-check-green.png";
import ChatButton from "../../Components/ChatButton";
import UserContest from "../../ContextAPI/UserContest";
import CountrySelect from "../../Components/CountryDropDown";
import {
  CheckFactorVerification,
  Enable2FA_Api,
  UpdateProfileApi,
  UpdateProfilePassword,
  getUserDetail,
} from "../../Api";
import { Notifications } from "../../Components/Toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { PhoneNumberUtil } from "google-libphonenumber";
import { CountrySelector, PhoneInput } from "react-international-phone";
import {
  TextInputRegex,
  colorRed,
  emailInputRegex,
  emailRegex,
  passwordRegex,
} from "../../Constants";
import Select from "react-select";
import countryList from "react-select-country-list";
import { PulseLoader } from "react-spinners";
import OTPInput from "react-otp-input";

function MyProfileDetails({ setShowFooter, setShowNavBar }) {
  const {
    CurrentStage,
    TotalSaleInStage,
    UserDetail,
    SetUserDetail,
    OverAllSaleToken,
    SetPromocode,
    Promocode,
  } = useContext(UserContest);

  const phoneUtil = PhoneNumberUtil.getInstance();
  const [twofaAuthenticationShow, settwofaAuthenticationShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(true);
  const [OTP, setOTP] = useState(false);

  const Countyoptions = useMemo(() => countryList().getData(), []);
  const [ShowOTP, setShowOTP] = useState(false);
  const [FactorLoader, setFactorLoader] = useState(false);

  const [updateProfileLoader, setUpdateProfileLoader] = useState(false);
  const [dialedCode, setDialedCode] = useState();
  const [updateUserDetail, setUpdateUserDetail] = useState(UserDetail);
  const [resetPassword, setResetPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  useEffect(() => {
    setShowFooter(false);
    setShowNavBar(false);
  }, [setShowFooter, setShowNavBar]);
  useEffect(() => {
    setUpdateUserDetail(UserDetail);
  }, [UserDetail]);
  const UpdateProfile = (setting) => {
    if (!updateProfileLoader) {
      setUpdateProfileLoader(true);
      // console.log(updateUserDetail)
      let temp = { ...updateUserDetail, FaEnabled: false };
      UpdateProfileApi(temp)
        .then(() => {
          getUserDetail()
            .then((detail) => {
              // navigate('/Dashboard')
              SetUserDetail(detail);
            })
            .catch(() => {
              setUpdateProfileLoader(false);
              if (localStorage.getItem("JwtToken")) {
                localStorage.clear();
              }

              // navigate('/')
            });
          if (setting)
            Notifications("success", "Settings Updated Successfully");
          else Notifications("success", "Profile Updated Successfully");
          setUpdateProfileLoader(false);
        })
        .catch(() => {
          setUpdateProfileLoader(false);
        });
    }
  };
  const ChangePassword = () => {
    if (resetPassword.newPassword === resetPassword.confirmPassword) {
      let temp = {
        id: UserDetail?.id,
        password: resetPassword.oldPassword,
        newPassword: resetPassword.newPassword,
      };
      if (!updateProfileLoader) {
        setUpdateProfileLoader(true);
        UpdateProfilePassword(temp)
          .then(() => {
            setUpdateProfileLoader(false);
            Notifications("success", "Password updated successfully");
            getUserDetail()
              .then((detail) => {
                // navigate('/Dashboard')
                SetUserDetail(detail);
              })
              .catch(() => {
                setUpdateProfileLoader(false);
                if (localStorage.getItem("JwtToken")) {
                  localStorage.clear();
                }
                navigate("/");
              });
            setResetPassword({
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
          })
          .catch(() => {
            setUpdateProfileLoader(false);
            Notifications("error", "Old password does not match");
          });
      }

      // console.log(resetPassword)
    }
  };

  useEffect(() => {
    validatePhone();
  }, [updateUserDetail]);

  const validatePhone = () => {
    try {
      // console.log( )
      const parsedPhone = phoneUtil.parseAndKeepRawInput(
        updateUserDetail?.phonenumber,
        "pk"
      ); // Specify the default region code
      const isValidPhone = phoneUtil.isValidNumber(parsedPhone);
      setIsValid(isValidPhone);
    } catch (error) {
      setIsValid(false);
    }
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "20px",
      // height:'42px',
      // innerHeight:'42px',
      outerHeight: "42px",
      border: "1px solid #ccc",
      boxShadow: "none",
    }),
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  const ConfigureOTP = () => {
    setFactorLoader(true);
    Enable2FA_Api(UserDetail?.id)
      .then(() => {
        setFactorLoader(false);
        setShowOTP(true);
        Notifications("success", "OTP has sent to your Registered Email ");
      })
      .catch(() => {
        setFactorLoader(false);
        Notifications("error", "Some Error has occurred");
      });
  };

  const ValidateTwoFactor = () => {
    setFactorLoader(true);
    const data = {
      user_id: UserDetail?.id,
      otp: OTP,
      twofactor: updateUserDetail?.twofactor ? false : true,
    };
    CheckFactorVerification(data)
      .then(() => {
        setFactorLoader(false);

        Notifications(
          "success",
          "Two Factor Authentication Enabled Successfully"
        );
        setOTP(false);
        setShowOTP(false);
        getUserDetail()
          .then((detail) => {
            // navigate('/Dashboard')
            SetUserDetail(detail);
          })
          .catch(() => {
            setUpdateProfileLoader(false);
            if (localStorage.getItem("JwtToken")) {
              localStorage.clear();
            }
            navigate("/");
          });
      })
      .catch(() => {
        setFactorLoader(false);
        setShowOTP(false);
        Notifications("error", "OTP does not match");
      });
  };
  return (
    <>
      <NavBarDash />
      <ChatButton />
      <section className="admin-panel container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" className="text-center">
                <h2>
                  <img src={arrowleft} alt="Arrow Left" /> My Profile Details{" "}
                  <img src={arrowright} alt="Arrow Right" />
                </h2>
                <div className="spacer-20" />
                {/* <div className="flex-div-sm align-items-center mb-text-left">
                  <Link
                    className="reg-btn br-radius letter-spacing-1 trans mmt-3"
                    to="/AccountActivities"
                  >
                    Account Activities
                  </Link>
                </div> */}
              </Col>
              <Col xl={{ span: 8, offset: 2 }}>
                <Tabs
                  onSelect={() => {
                    setUpdateUserDetail(UserDetail);
                  }}
                  defaultActiveKey="PersonalInformation"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab
                    eventKey="PersonalInformation"
                    title={
                      <>
                        <img src={iconuser} alt="User" /> Personal Information
                      </>
                    }
                  >
                    <Form>
                      <Row>
                        <Col xl="6" lg="6" md="6">
                          <Form.Group
                            className="mb-2"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              onChange={(e) => {
                                const inputVal = e.target.value;
                                if (
                                  TextInputRegex.test(inputVal) ||
                                  inputVal === ""
                                ) {
                                  setUpdateUserDetail((prev) => ({
                                    ...prev, // Correcting the typo here
                                    name: e.target.value, // Correcting the typo here
                                  }));
                                }
                              }}
                              value={updateUserDetail?.name}
                              autoComplete="new-name"
                              className="input-name"
                              placeholder="Enter Your Name"
                            />
                          </Form.Group>
                        </Col>
                        <Col xl="6" lg="6" md="6">
                          <Form.Group
                            className="mb-2"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              value={updateUserDetail?.email}
                              onChange={(e) => {
                                const inputVal = e.target.value;
                                if (
                                  emailInputRegex.test(inputVal) ||
                                  inputVal === ""
                                ) {
                                  setUpdateUserDetail((prev) => ({
                                    ...prev, // Correcting the typo here
                                    email: inputVal, // Correcting the typo here
                                  }));
                                }
                              }}
                              autoComplete="new-email"
                              className="input-email"
                              placeholder="Enter Your Email"
                            />
                          </Form.Group>
                        </Col>
                        <Col xl="6" lg="6" md="6">
                          <Form.Group
                            className="mb-2"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Phone Number</Form.Label>

                            <PhoneInput
                              style={{ width: "100%" }}
                              defaultCountry={"uk"}
                              value={updateUserDetail?.phonenumber ?? `+1`}
                              onChange={(inputValue, country) => {
                                setDialedCode(country.country.dialCode);
                                // console.log(inputValue, country)
                                setUpdateUserDetail((prev) => ({
                                  ...prev, // Correcting the typo here
                                  phonenumber: inputValue, // Correcting the typo here
                                }));
                              }}
                            />
                            {updateUserDetail.phonenumber ??
                            "+1" === `+${dialedCode}` ? (
                              <></>
                            ) : (
                              <>
                                {!isValid && (
                                  <div style={{ color: colorRed }}>
                                    Phone is not valid
                                  </div>
                                )}
                              </>
                            )}

                            {/* <Form.Control
                                type="text"
                                value={updateUserDetail?.phonenumber}


                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  // Allow only numbers from 0 to 9 and an empty string (for backspace)
                                  if (NumberRegex.test(inputValue)) {
                                    setUpdateUserDetail((prev) => ({
                                      ...prev, // Correcting the typo here
                                      phonenumber: e.target.value, // Correcting the typo here
                                    }));
                                  }
                                }}
                                onKeyPress={(e) => {
                                  // Allow only numbers from 0 to 9 and backspace
                                  const keyCode = e.keyCode || e.which;
                                  const keyValue = String.fromCharCode(keyCode);
                                  if (!NumberRegex.test(keyValue) && keyCode !== 8) {
                                    e.preventDefault();
                                  }
                                }}
                                autoComplete="new-number"
                                placeholder="Enter Your number"
                              /> */}
                          </Form.Group>
                        </Col>
                        <Col xl="6" lg="6" md="6">
                          <Form.Group
                            className="mb-2"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control
                              type="date"
                              value={updateUserDetail?.date_of_birth}
                              onChange={(e) => {
                                setUpdateUserDetail((prev) => ({
                                  ...prev, // Correcting the typo here
                                  date_of_birth: e.target.value, // Correcting the typo here
                                }));
                              }}
                              max={new Date().toISOString().split("T")[0]} // Disable future dates in the browser
                              autoComplete="new-number"
                              placeholder="Enter Your number"
                            />
                          </Form.Group>
                        </Col>
                        <Col xl="6" lg="6" md="6">
                          <Form.Group
                            className="input-cntnr"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Your Nationality</Form.Label>

                            <Select
                              placeholder={"select country"}
                              styles={customStyles}
                              options={Countyoptions}
                              value={Countyoptions.find(
                                (item) =>
                                  item.label === updateUserDetail.nationality
                              )}
                              onChange={(value) => {
                                setUpdateUserDetail((prev) => ({
                                  ...prev, // Correcting the typo here
                                  nationality: value.label, // Correcting the typo here
                                }));
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col xl="12" lg="12" md="12" className="text-center">
                          <div className="spacer-30" />
                          <Link
                            onClick={() => {
                              UpdateProfile();
                            }}
                            className="reg-btn br-radius trans"
                            to={void 0}
                          >
                            {updateProfileLoader ? (
                              <PulseLoader size={11} color="white" />
                            ) : (
                              "Update profile"
                            )}
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                  <Tab
                    eventKey="MySettings"
                    title={
                      <>
                        <img src={iconsetting} alt="Setting" /> My Settings
                      </>
                    }
                  >
                    <h4 className="whitecolor font-weight-bolder">
                      Security Settings
                    </h4>
                    <div className="spacer-10" />
                    <Form>
                      {["checkbox"].map((type) => (
                        <div key={`default-${type}`} className="mb-3">
                          <Form.Check // prettier-ignore
                            type={type}
                            checked={updateUserDetail?.activitylog}
                            onChange={(e) => {
                              setUpdateUserDetail((prev) => ({
                                ...prev, // Correcting the typo here
                                activitylog: !updateUserDetail?.activitylog, // Correcting the typo here
                              }));
                            }}
                            value={updateUserDetail?.activitylog}
                            className="mb-2"
                            id={`default-${type}`}
                            label="Save my activities log"
                          />
                          <Form.Check
                            checked={updateUserDetail?.emailalert}
                            onChange={(e) => {
                              setUpdateUserDetail((prev) => ({
                                ...prev, // Correcting the typo here
                                emailalert: !updateUserDetail?.emailalert, // Correcting the typo here
                              }));
                            }}
                            type={type}
                            id={`default-${type}`}
                            label="Alert me by email in case of unusual activity in my account"
                          />
                        </div>
                      ))}
                    </Form>
                    <div className="spacer-10" />
                    <div className="text-center">
                      <Link
                        onClick={() => {
                          UpdateProfile(true);
                        }}
                        className="reg-btn br-radius trans letter-spacing-1"
                        to={void 0}
                      >
                        {updateProfileLoader ? (
                          <PulseLoader size={11} color="white" />
                        ) : (
                          " Update settings"
                        )}
                      </Link>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="UpdateLoginCredentials "
                    title={
                      <>
                        <img src={iconlock} alt="User" /> Login Credentials
                      </>
                    }
                  >
                    <Form>
                      <Row>
                        <Col xl="6" lg="6" md="6">
                          <Form.Group
                            className="mb-2"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Your login</Form.Label>
                            <div className="log-i-cntnr">
                              <Form.Control
                              value={UserDetail?.email}
                              readOnly
                                type="text"
                                className="input-password"
                                placeholder="Enter your login"
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col xl="6" lg="6" md="6">
                          <Form.Group
                            className="mb-2"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Old Password</Form.Label>
                            <div className="log-i-cntnr">
                              <Form.Control
                                type={showPassword ? "text" : "password"}
                                value={resetPassword.oldPassword}
                                onChange={(e) => {
                                  setResetPassword((prev) => ({
                                    ...prev, // Correcting the typo here
                                    oldPassword: e.target.value, // Correcting the typo here
                                  }));
                                }}
                                autoComplete="new-name"
                                className="input-password"
                                placeholder="Enter old password"
                              />
                              {!showPassword ? (
                                <FaEyeSlash
                                  className="FaEyeSecond"
                                  onClick={() => {
                                    setShowPassword(!showPassword);
                                  }}
                                />
                              ) : (
                                <FaEye
                                  className="FaEyeSecond"
                                  onClick={() => {
                                    setShowPassword(!showPassword);
                                  }}
                                />
                              )}
                            </div>
                            {/* className="log-i-cntnr" */}
                          </Form.Group>
                        </Col>
                        <Col xl="6" lg="6" md="6">
                          <Form.Group
                            className="mb-2"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>New Password</Form.Label>
                            <div className="log-i-cntnr">
                              <Form.Control
                                type={showPassword ? "text" : "password"}
                                value={resetPassword.newPassword}
                                onChange={(e) => {
                                  setResetPassword((prev) => ({
                                    ...prev, // Correcting the typo here
                                    newPassword: e.target.value, // Correcting the typo here
                                  }));
                                }}
                                autoComplete={false}
                                className="input-password"
                                placeholder="Enter new Password"
                              />
                              {!showPassword ? (
                                <FaEyeSlash
                                  className="FaEyeSecond"
                                  onClick={() => {
                                    setShowPassword(!showPassword);
                                  }}
                                />
                              ) : (
                                <FaEye
                                  className="FaEyeSecond"
                                  onClick={() => {
                                    setShowPassword(!showPassword);
                                  }}
                                />
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col xl="6" lg="6" md="6">
                          <Form.Group
                            className="mb-2"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Confirm Password</Form.Label>
                            <div className="log-i-cntnr">
                              <Form.Control
                                type={showPassword ? "text" : "password"}
                                value={resetPassword.confirmPassword}
                                onChange={(e) => {
                                  setResetPassword((prev) => ({
                                    ...prev, // Correcting the typo here
                                    confirmPassword: e.target.value, // Correcting the typo here
                                  }));
                                }}
                                autoComplete={false}
                                className="input-password"
                                placeholder="Enter comfirm password"
                              />
                              {!showPassword ? (
                                <FaEyeSlash
                                  className="FaEyeSecond"
                                  onClick={() => {
                                    setShowPassword(!showPassword);
                                  }}
                                />
                              ) : (
                                <FaEye
                                  className="FaEyeSecond"
                                  onClick={() => {
                                    setShowPassword(!showPassword);
                                  }}
                                />
                              )}
                            </div>
                            {resetPassword.newPassword !==
                              resetPassword.confirmPassword &&
                              resetPassword.confirmPassword &&
                              resetPassword.newPassword && (
                                <p style={{ color: colorRed }}>
                                  Password does not match.
                                </p>
                              )}
                          </Form.Group>
                        </Col>
                        <Col xl="12" lg="12" md="12">
                          <div className="spacer-20" />
                          <p className="m-0 d-flex align-items-start mb-2">
                            <img
                              className="mr-1"
                              src={iconerror}
                              alt="icon-error"
                            />{" "}
                            Password should be a minimum of 6 digits and include
                            lower and uppercase letter.
                          </p>
                          <p className="d-flex align-items-start">
                            <img
                              className="mr-1"
                              src={iconerror}
                              alt="icon-error"
                            />{" "}
                            Your password will only change after your
                            confirmation by email.
                          </p>
                        </Col>
                        <Col xl="12" lg="12" md="12" className="text-center">
                          <div className="spacer-30" />
                          <Link
                            onClick={() => {
                              ChangePassword();
                            }}
                            className="reg-btn br-radius trans letter-spacing-1"
                            to={void 0}
                          >
                            {updateProfileLoader ? (
                              <PulseLoader size={11} color="white" />
                            ) : (
                              "  Update Password"
                            )}
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  </Tab>
                </Tabs>
                <div className="factor-status-panel">
                  <div className="text-center">
                    <ul className="btn-list text-center">
                      <li>
                        <h4 className="chakra">
                          {" "}
                          <b>
                            <img src={iconcheck} alt="icon check" /> Two-Factor
                            Verification
                          </b>
                        </h4>
                      </li>
                      <li>
                        <div className="purple-post">
                          <p className="m-0 ">Current Status:</p>
                          {updateUserDetail?.twofactor ? (
                            <span className="reject-span active letter-spacing-1 mx-1">
                              <span className="active">
                                <img src={iconcheckgreen} alt="icon check" />{" "}
                                Active
                              </span>
                            </span>
                          ) : (
                            <span className="reject-span active letter-spacing-1 mx-1">
                              <span
                                className="active"
                                style={{ color: "#F3545E" }}
                              >
                                <img src={iconcross} alt="icon cross" />{" "}
                                Disabled
                              </span>
                            </span>
                          )}
                        </div>
                        {/* <p className="font-weight-bold m-0 ">Current Status:</p>
                        {updateUserDetail?.twofactor ? (
                          <span className="reject-span active letter-spacing-1 ml-3">
                            <span className="active">Active</span>
                          </span>
                        ) : (
                          <span
                            className="reject-span active letter-spacing-1 ml-3"
                            style={{ backgroundColor: colorRed }}
                          >
                            <span
                              className="active"
                              style={{ backgroundColor: colorRed }}
                            >
                              Disabled
                            </span>
                          </span>
                        )} */}
                      </li>
                    </ul>
                  </div>
                  <p className="f-18 lightgrey-color">
                    With 2-Step Verification, also called two-factor
                    authentication, you can add an extra layer of security to
                    your account in addition to your password.
                  </p>
                  <div className="text-center">
                    <Button disabled
                      className="reg-btn br-radius letter-spacing-1"
                      to="javascript:void(0);"
                      onClick={() => {
                        setOTP(false);
                        ConfigureOTP();
                      }}
                    >
                      {FactorLoader ? (
                        <PulseLoader size={11} color="white" />
                      ) : updateUserDetail?.twofactor ? (
                        "Disable 2FA"
                      ) : (
                        "Enable 2FA"
                      )}
                    </Button>
                    <div className="spacer-20" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>

      {/* 2Fa Authentication Modal Starts Here */}
      <Modal
        size="lg"
        show={twofaAuthenticationShow}
        centered
        onHide={() => settwofaAuthenticationShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button
            className="close-btn"
            onClick={() => settwofaAuthenticationShow(false)}
          >
            <i className="fa fa-close"/>
          </Button>
            <h3 className="chakra text-center">
              <img src={arrowleft} alt="Arrow Left" /> Enable 2FA Authentication{" "}
              <img src={arrowright} alt="Arrow Right" />
            </h3>
        
          <div className="spacer-20" />
          <p className="m-0">
            <b>Step 1:</b> Install this app from{" "}
            <Link className="simple-link purple">Google Play</Link> store or
            <Link className="simple-link purple">App Store.</Link>
          </p>
          <p className="mt-2">
            <b>Step 2:</b> Scan the below QR code by your Google Authenticator
            app, or you can add account manually.
          </p>
          <div className="spacer-20" />
          <h6 className="f-18 chakra">Manually add Account:</h6>
          <p className="m-0">
            Account Name:
            <b className="yellow-color"> AlgoTech</b>
          </p>
          <p>
            Key:
            <b className="yellow-color"> 6PSGTIQRT7S2ZRZI</b>
          </p>
          <div className="spacer-10" />
          <div className="flex-div-md align-items-center text-align-left">
            <div>
              <img src={qrcode} alt="QR Code" />
            </div>
            <div className="pl-4 w-100 mv-p-0">
              <Form>
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Enter Google Authenticator Code</Form.Label>
                  <Form.Control
                    type="email"
                    autoComplete="new-email"
                    className="input-email"
                    placeholder="Enter Google Authenticator Code"
                  />
                </Form.Group>
              </Form>
              <Button className="reg-btn br-radius letter-spacing-1">
                Enable 2FA
              </Button>
            </div>
          </div>
          <div className="spacer-40" />
          <p className="m-0 f-18">
            <b className="notranslate">Note:</b> If you lose your phone or
            delete Google Authenticator, you will no longer be able to log in to
            your account. your account.
          </p>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={ShowOTP}
        centered
        onHide={() => setShowOTP(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button className="close-btn" onClick={() => setShowOTP(false)}>
            <i className="fa fa-close"/>
          </Button>
          <h3 className="chakra text-center">
            <img src={arrowleft} alt="Arrow Left"/> {' '}OTP has sent to your Registered Email {' '}<img src={arrowright} alt="Arrow Right"/>
          </h3>
          <div className="spacer-20"/>
          <div className="otpinput text-center">
            <OTPInput
              value={OTP}
              onChange={setOTP}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
            <br />
          </div>
          <div className="text-center">
            <a
              className="text-center simple-link"
              onClick={() => ConfigureOTP()}
              style={{ cursor: "pointer" }}
            >
              Resend OTP
            </a>
            <div className="spacer-20"/>
            <Button
              onClick={() => {
                ValidateTwoFactor();
              }}
              className="reg-btn br-radius letter-spacing-1"
            >
              {FactorLoader ? (
                <PulseLoader size={11} color="white" />
              ) : (
                "  Validate OTP"
              )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* 2Fa Authentication Modal Ends Here */}
    </>
  );
}
export default MyProfileDetails;
