import React from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col, Button } from "react-bootstrap";
import bgimg1 from "../Assets/img/Giveaway/bg.png";
import bgimg from "../Assets/img/Giveaway/footer-bg.png";
import arrowleft from "../Assets/img/Icons/icon-arrow-1.png";
import arrowright from "../Assets/img/Icons/icon-arrow-2.png";
import { PresaleButton } from "../Components/Buttons";
import EntiresPanel from "../Components/EntiresPanel";
function Giveaway({ setSignupShow }) {
  return (
    <>
      {/* Terms And Conditions Starts Here */}
      <section className="giveaway-pnl container-fluid">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${bgimg1})` }}
        />
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" className="text-panel">
                <h1>
                  <img src={arrowleft} alt="Arrow Left" />{" "}
                  <span>Win $250k</span> With NewDawnFinance{" "}
                  <img src={arrowright} alt="Arrow Right" />
                </h1>
                <div className="spacer-40" />
                <h4>
                  Participate in <b>NewDawnFinance Mega $250k Giveaway</b> for a
                  chance to be one of the <b>10 LUCKY</b> winners who will
                  receive <b>$25,000 worth of NDFT each!</b> Increase your
                  chances of winning by completing all tasks and unlocking bonus
                  entries. Act now to seize this opportunity by sharing and
                  participating.
                </h4>
                <div className="spacer-10" />
                <h4>
                  <b>PLEASE NOTE:</b> To qualify, it is
                  essential to have a minimum participation of $100 in the
                  NewDawnFinance Presale.
                </h4>
                <div className="spacer-30" />
                <PresaleButton setSignupShow={setSignupShow} />
              </Col>
              <Col xl="12" lg="12" md="12" className="text-center">
                <EntiresPanel setSignupShow={setSignupShow} />
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Terms And Conditions Ends Here */}
      <Container className="join-presale-now text-center" fluid>
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${bgimg})` }}
        />
        <Row>
          <Container>
            <Row>
              <Col xl={{ span: 8, offset: 2 }}>
                <h2>
                  Don't miss out - claim your $25,000<br></br> worth of NDFT! 🌟
                </h2>
                <div className="mt-4 mb-1">
                  <Link
                    onClick={() => {
                      setSignupShow(true);
                    }}
                    className="reg-btn br-radius m-w-100"
                    to={void 0}
                  >
                    Join Presale NOW
                  </Link>
                  <div className="spacer-50"/>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  );
}
export default Giveaway;
